import React, {Component} from "react";
import axios from 'axios';
import Loading from "../../Loading";
import {APIURL} from "../../../config/config";
import {NumberUtil} from "../../../utils/number-util";
import NumberFormat from "react-number-format";
import {AlertSuccess, AlertWarning} from "../../Alert/Alert";

class EstimatedRevenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sumcar_in_branchs: [],
            // expenses: [],
            branches: [],
            lists: []
        }
        this.loadCarInData = this.loadCarInData.bind(this);
        this.loadBranchData = this.loadBranchData.bind(this);
        this.onSearchByCarStatus = this.onSearchByCarStatus.bind(this);
        this.updateEstimatedStatus = this.updateEstimatedStatus.bind(this);
        this.updateEstimatedRevenue = this.updateEstimatedRevenue.bind(this);
        this.updateEstimated = this.updateEstimated.bind(this);
    }

   async componentDidMount() {
        // load ต้นทุนอย่างย่อ
       await this.loadBranchData()
       await this.loadCarInData()
       await this.onSearchByCarStatus()
        // load car_sell ขายแล้วรอเงินเข้า
    }

    async loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    async onSearchByCarStatus(){
        let car_status_id = 23

        this.setState({
            isLoading: true,
            lists: []
        }, () => {
            axios.get(APIURL + '/car_sell/search_status/'+car_status_id)
                .then(res => {
                    // let data = {...res.data, status: 'normal'}
                    let data = res.data.map(data => {
                        return {
                            ...data,
                            estimated_status_edit: data.estimated_status,
                            estimated_revenue_edit: data.estimated_revenue,
                            status: 'normal'
                        }
                    })
                    this.setState({
                        lists: data,
                        isLoading: false
                    })

                }).catch(error => {
                //console.log(error)
                this.setState({lists: []})
            })
        })
    }

    async loadCarInData() {
        let data =  {
            "start_date": "2023-11-13",
            "end_date": "2023-11-13",
            "data_by": "all",
            "branch_id": "",
            "order_by": "car_in_desc",
            "person_vat_id": ""
        }

        axios.post(APIURL + '/report_cost_summary/carin', data)
            .then(res => {
                let data = res.data
                let cars = data.cars
                let expenses = data.expenses

                // let _ = require('lodash');
                // let branch = _.unionBy(cars, 'branch_id')
                // let expenses = this.state.expenses

                let branches = this.state.branches
                let sumcar_in_branchs = []
                branches.map(branch => {
                    let branch_id = branch.branch_id
                    let branch_name = branch.branch_name

                    let car_in_branchs = cars.filter(car => car.branch_id === branch_id)
                    let car_count = 0
                    let sum_total = 0
                    car_in_branchs.map(car => {
                        let car_id = car.car_id
                        let car_cost = parseFloat(String(car.car_cost)).toFixed(2)
                        let vat_buy = parseFloat(String(car.vat_buy)).toFixed(2)
                        let car_cost_repair = car.car_cost_repair === null ? 0 : parseFloat(String(car.car_cost_repair)).toFixed(2)

                        let car_cost_with_vat = Number(car.car_cost)+Number(vat_buy)
                        let car_cost_with_vat_fix = car_cost_with_vat.toFixed(2)

                        let cost_operation = car.cost_operation; // ค่าดำเนินการ
                        let cost_service = car.cost_service; // ค่าบริการ
                        let cost_transport = car.cost_transport; // ค่าขนย้าย
                        let cost_porobor_lan = car.cost_porobor_lan; // ค่า พรบ. จากลาน
                        let cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan; // ค่าบังคับต่อภาษี จากลาน
                        let cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan;  // ค่าบังคับโอนเข้าเต็นท์ จากลาน
                        let cost_check_ton = car.cost_check_ton;  // ค่าเช็คต้น

                        let expense_by_car = expenses.filter(expense => expense.car_id===car_id)
                        let car_cost_expenses_other_no_vat = expense_by_car.reduce((acc, expense)=>{
                            if (expense.include_vat === 1) {
                                let expense_amount_no_vat = expense.expense_amount - expense.expense_amount_vat
                                return acc + expense_amount_no_vat
                            }
                            return acc + expense.expense_amount
                        }, 0);

                        
                        let car_cost_other_sum = Number(car.car_cost_other)  + Number(car.car_cost_commissions) + Number(car.car_cost_act) + Number(car.car_cost_tax)
            
                        car_cost_other_sum += Number(car_cost_expenses_other_no_vat);
                        car_cost_other_sum += Number(cost_operation);
                        car_cost_other_sum += Number(cost_service);
                        car_cost_other_sum += Number(cost_transport);
                        car_cost_other_sum += Number(cost_porobor_lan);
                        car_cost_other_sum += Number(cost_bangkhab_phasi_lan);
                        car_cost_other_sum += Number(cost_bangkhab_xon_lan);
                        car_cost_other_sum += Number(cost_check_ton);
                        car_cost_other_sum += Number(car_cost_repair);

                        car_cost_other_sum = Number(car_cost_other_sum.toFixed(2))

                        let total = Number(car_cost_with_vat_fix) + Number(car_cost_other_sum)
                        sum_total += total
                        car_count++
                    });

                    let car_in_branch = {
                        branch_id: branch_id,
                        branch_name: branch_name,
                        sum_total,
                        car_count
                    }
                    sumcar_in_branchs.push(car_in_branch)
                })

                this.setState({
                    isLoading: false,
                    sumcar_in_branchs: sumcar_in_branchs,
                    // expenses: expenses
                })

            }).catch(error => {
            console.log('error:', error)
        })

    }

    updateEstimatedStatus(car, e, index) {
        let lists = this.state.lists
        let estimated_status = e.target.value
        let car_update = car
        car_update.estimated_status_edit = estimated_status
        car_update.status = 'edit'
        lists[index] = car_update
        this.setState({
            lists: lists
        })
    }

    updateEstimatedRevenue(car, value, index) {
        let lists = this.state.lists
        let estimated_revenue = value
        let car_update = car
        car_update.estimated_revenue_edit = estimated_revenue
        car_update.status = 'edit'
        lists[index] = car_update
        this.setState({
            lists: lists
        })
    }

    updateEstimated() {
        let lists = this.state.lists
        let carSellEdit = lists.filter(car => car.status === 'edit')

        if (carSellEdit.length === 0) {
            AlertWarning('กรุณาแก้ไขอย่างน้อย 1 รายการ')
            return
        }

        let data = carSellEdit.map(car => {
            return {
                car_sell_id: car.car_sell_id,
                estimated_revenue: car.estimated_revenue_edit,
                estimated_status: car.estimated_status_edit,
            }
        })

        this.setState({isLoading: true}, () => {
            let url = APIURL+'/car_sell/estimated'
            axios.post(url, data).then( async res => {
                let result = res.data
                if (result === 'success') {
                    AlertSuccess('บันทึกข้อมูลประมาณการเรียบร้อยแล้ว')
                    await this.onSearchByCarStatus()
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(err => {
                console.log('error:', err)
            })
        });

    }

    render() {

        let sum_cost_all = this.state.sumcar_in_branchs.reduce((acc, car) => acc + Number(car.sum_total), 0)
        let sum_car_count = this.state.sumcar_in_branchs.reduce((acc, car) => acc + Number(car.car_count), 0)

        let sum_car_cost_with_vat = 0
        let sum_estimated_revenue_edit = 0

        let carList = this.state.lists.map((car, index) => {
            let car_license_plate_new = car.car_license_plate_new.trim()
            let car_license_plate_old = car.car_license_plate_old.trim()
            let car_license_old_new = car.car_license_plate_old +' ' + car.province_name_old+ ' / '+ car.car_license_plate_new+' '+ car.province_name_new
            let car_license = car_license_plate_new === car_license_plate_old ? car.car_license_plate_new+' '+car.province_name_new : car_license_old_new
            //
            let car_brand_name = car.car_brand_name
            let car_model_name = car.car_model_name
            let car_year_name = car.car_year_name
            let car_color_name = car.car_color_name
            let car_name = car_brand_name+' '+car_model_name+' / '+car_year_name+' / '+car_color_name
            //
            let vat_buy = parseFloat(String(car.vat_buy)).toFixed(2)
            let car_cost_with_vat = Number(car.car_cost)+Number(vat_buy)
            sum_car_cost_with_vat += Number(car_cost_with_vat)
            //
            let sell_finance_contract = car.sell_finance_contract
            let estimated_status = car.estimated_status
            let estimated_revenue = car.estimated_revenue

            let estimated_status_edit = car.estimated_status_edit
            let estimated_revenue_edit = car.estimated_revenue_edit
            sum_estimated_revenue_edit += Number(estimated_revenue_edit)
            let status = car.status

            return <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{car_license}</td>
                <td className="text-center">{car_name}</td>
                <td className="text-center">{sell_finance_contract}</td>
                <td className="text-center">
                    <input type="text"
                           className={status === 'normal' || (estimated_status === estimated_status_edit)  ? "form-control btn-outline-info" : "form-control btn-outline-warning"}
                           placeholder="สถานะ"
                           value={estimated_status_edit}
                           maxLength={150}
                           onChange={(e) => this.updateEstimatedStatus(car, e, index)}
                    />
                </td>
                <td className="text-right">{car_cost_with_vat.toLocaleString()}</td>
                <td className="text-center">
                    <NumberFormat
                        className={status === 'normal' || (estimated_revenue === estimated_revenue_edit)  ? "form-control btn-outline-info text-right" : "form-control btn-outline-warning"}
                        thousandSeparator={true}
                        prefix={'฿'}
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={estimated_revenue_edit}
                        onValueChange={(values) => {
                            const {value} = values
                            this.updateEstimatedRevenue(car, value, index)
                        }}
                    />
                </td>
            </tr>
        })


        return (
           <div>
            <Loading isLoading={this.state.isLoading} />
               
               <div className="card card-accent-primary">
                   <div className="card-header">
                       <div className="row">
                           <div className="col-10">
                               <strong className="text-title">ประมาณการเงินเข้า</strong>
                           </div>
                           <div className="col-2">
                               {/*<button
                                   onClick={() => {

                                   }}
                                   type="button"
                                   style={{ width: 150 }}
                                   className={"btn btn-success  btn-sm btn-block"}
                               >
                                   <i className="icon-cloud-download">&nbsp;Excel </i>
                               </button>*/}
                           </div>
                       </div>
                   </div>
                   <div className="card-block">
                       <div className="row">
                           <div className="col-12">
                             <strong><h6> สรุปยอดรถซื้อเข้ารวม Vat ทั้ง {this.state.branches.length} สาขา </h6></strong>
                           </div>
                           <div className="col-12">
                               <table className="table table-bordered table-sm">
                                 <thead>
                                    <tr>
                                        <th className="text-center font-weight-bold" style={{width: 140}} >สาขา</th>
                                        <th className="text-center font-weight-bold">ยอดซื้อเข้า</th>
                                        <th className="text-center font-weight-bold">จำนวน</th>
                                    </tr>
                                 </thead>
                                  <tbody>
                                  {
                                      this.state.sumcar_in_branchs.map((carsum, index) => {
                                          return (
                                              <tr key={index}>
                                                  <td>{carsum.branch_name}</td>
                                                  <td className="text-right">{parseInt(carsum.sum_total, 10).toLocaleString()}</td>
                                                  <td className="text-center">{carsum.car_count}</td>
                                              </tr>
                                          )
                                      })
                                  }
                                  <tr>
                                      <td> <strong>รวม</strong> </td>
                                      <td className="text-right">
                                          <strong> {parseInt(sum_cost_all, 10).toLocaleString()} </strong>
                                      </td>
                                      <td className="text-center">
                                          <strong> {parseInt(sum_car_count, 10).toLocaleString()} </strong>
                                      </td>
                                  </tr>
                                  </tbody>

                               </table>
                           </div>
                       </div>
                       <div className="row mt-2">
                           <div className="col-12">
                               <strong><h6>รายการรถยนต์ที่เงินยังไม่เข้า</h6></strong>
                           </div>
                           <div className="col-12">
                               <table className="table table-bordered table-sm">
                                   <thead>
                                   <tr>
                                       <th className="text-center font-weight-bold" style={{width: 140}} >คันที่</th>
                                       <th className="text-center font-weight-bold">ทะเบียน</th>
                                       <th className="text-center font-weight-bold">รุ่น</th>
                                       <th className="text-center font-weight-bold">ชื่อพนักงานไฟแนนซ์</th>
                                       <th className="text-center font-weight-bold">สถานะ</th>
                                       <th className="text-center font-weight-bold">ราคาซื้อ+Vat</th>
                                       <th className="text-center font-weight-bold">ประมาณการเงินเข้า</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                   {carList}
                                   <tr>
                                       <td colSpan={5}>&nbsp;</td>
                                       <td className="text-right">
                                          <strong>  {sum_car_cost_with_vat.toLocaleString()} </strong>
                                       </td>
                                       <td className="text-right">
                                          <strong> {sum_estimated_revenue_edit.toLocaleString()} </strong>
                                       </td>
                                   </tr>
                                   </tbody>
                               </table>
                           </div>
                       </div>

                   </div>
                   <div className="card-footer text-right">
                       <button type="button"
                               className="btn btn-sm btn-primary"
                               onClick={() => this.updateEstimated()}
                       >
                           <i className="fa fa-save"/> บันทึกประมาณการ
                       </button>
                   </div>


               </div>
           </div>
        )
    }
}



export default EstimatedRevenue