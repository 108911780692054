import React, { Component } from 'react';
import axios from 'axios'
import NumberFormat from 'react-number-format';
import {APIURL, PORT} from '../../../config/config'
import {connect} from 'react-redux' ;

class CarEditPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      car_cost: 0,
      car_sale_price: 0,
      car_cost_repair: 0,
      car_cost_tax: 0,
      car_cost_act: 0,
      car_cost_commissions: 0,
      car_cost_expenses_other: 0,
      car_cost_recipe_other: 0,
      car_net_profit: 0,
      vat_buy: 0,
      // at
      cost_operation: 0, // ค่าดำเนินการ
      cost_service: 0, // ค่าบริการ
      cost_transport: 0, // ค่าขนย้าย
      cost_porobor_lan: 0, // ค่า พรบ. จากลาน
      cost_bangkhab_phasi_lan: 0, // ค่าบังคับต่อภาษี จากลาน
      cost_bangkhab_xon_lan: 0, // ค่าบังคับโอนเข้า A.T. จากลาน
      cost_check_ton: 0, // ค่าเช็คต้น
      person_vat_id: 0
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnChangeNoSet = this.handleOnChangeNoSet.bind(this)
    this.onCarEditPriceSubmit = this.onCarEditPriceSubmit.bind(this)
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount(){
    let car = this.props.car ;
    //console.log("=====================CarEditPrice")
    //console.log(car)
    //alert(car.car_sale_price)
    this.setState({
      car_sale_price: car.car_sale_price,
      car_cost: car.car_cost,
      car_cost_repair: car.car_cost_repair,
      car_cost_tax: car.car_cost_tax,
      car_cost_act: car.car_cost_act,
      car_cost_commissions: car.car_cost_commissions,
      car_cost_expenses_other: car.car_cost_expenses_other,
      car_cost_recipe_other: car.car_cost_recipe_other,
      vat_buy: car.vat_buy,
      cost_operation : car.cost_operation,
      cost_service : car.cost_service,
      cost_transport : car.cost_transport,
      cost_porobor_lan : car.cost_porobor_lan,
      cost_bangkhab_phasi_lan : car.cost_bangkhab_phasi_lan,
      cost_bangkhab_xon_lan : car.cost_bangkhab_xon_lan,
      cost_check_ton : car.cost_check_ton,
      person_vat_id: car.person_vat_id === null ? 0 : Number(car.person_vat_id)
    });
  }

  handleOnChange(){

    let car_sale_price = this.state.car_sale_price ;
    let car_cost  = this.state.car_cost ;
    let car_cost_repair  = this.state.car_cost_repair ;
    let car_cost_tax  = this.state.car_cost_tax ;
    let car_cost_act  = this.state.car_cost_act;
    let car_cost_commissions  = this.state.car_cost_commissions;
    let car_cost_expenses_other  = this.state.car_cost_expenses_other ;
    let car_cost_recipe_other  = this.state.car_cost_recipe_other;
    //
    let cost_operation = this.state.cost_operation === '' ? 0 : this.state.cost_operation;
    let cost_service = this.state.cost_service === '' ? 0 : this.state.cost_service;
    let cost_transport = this.state.cost_transport === '' ? 0 : this.state.cost_transport;
    let cost_porobor_lan = this.state.cost_porobor_lan === '' ? 0 : this.state.cost_porobor_lan;
    let cost_bangkhab_phasi_lan = this.state.cost_bangkhab_phasi_lan === '' ? 0 : this.state.cost_bangkhab_phasi_lan;
    let cost_bangkhab_xon_lan = this.state.cost_bangkhab_xon_lan === '' ? 0 : this.state.cost_bangkhab_xon_lan;
    let cost_check_ton = this.state.cost_check_ton === '' ? 0 : this.state.cost_check_ton;
    let vat_buy = this.state.vat_buy === '' ? 0 : this.state.vat_buy;

    if(car_cost===''){
      car_cost = 0
    }

    if(car_cost_repair===''){
      car_cost_repair = 0
    }

    if(car_cost_tax===''){
      car_cost_tax = 0
    }


    if(car_cost_act===''){
      car_cost_act = 0
    }

    if(car_cost_commissions===''){
      car_cost_commissions = 0
    }


    if(car_cost_expenses_other===''){
      car_cost_expenses_other = 0
    }


    if(car_cost_recipe_other===''){
      car_cost_recipe_other = 0
    }


    let profite = 0 ;
    if(car_sale_price !== '' && car_sale_price > 0) {
      profite = Number(car_sale_price) - Number(car_cost) - Number(car_cost_repair) - Number(car_cost_tax) - Number(car_cost_act) - Number(car_cost_commissions) - Number(car_cost_expenses_other) + Number(car_cost_recipe_other);
      profite -= Number(cost_operation);
      profite -= Number(cost_service);
      profite -= Number(cost_transport);
      profite -= Number(cost_porobor_lan);
      profite -= Number(cost_bangkhab_phasi_lan);
      profite -= Number(cost_bangkhab_xon_lan);
      profite -= Number(cost_check_ton);
      profite -= Number(vat_buy);
    }

    this.setState({
      car_net_profit: profite
    });
  }

  handleOnChangeNoSet() {

  }

  onCarEditPriceSubmit(event){
    event.preventDefault()

    let car_id = this.props.car.car_id
    let car_sale_price = this.state.car_sale_price ;
    let car_cost  = this.state.car_cost === '' ? 0 : Number(this.state.car_cost) ;
    let car_cost_repair  = this.state.car_cost_repair ;
    let car_cost_tax  = this.state.car_cost_tax ;
    let car_cost_act  = this.state.car_cost_act;
    let car_cost_commissions  = this.state.car_cost_commissions;
    let car_cost_expenses_other  = this.state.car_cost_expenses_other ;
    let car_cost_recipe_other  = this.state.car_cost_recipe_other;
    let car_net_profit = this.state.car_net_profit;
    let vat_buy = this.state.vat_buy === '' ? 0 : Number(this.state.vat_buy);
    let person_vat_id = this.state.person_vat_id;

    // console.log('vat_buy=', vat_buy, typeof vat_buy)

    /*if (person_vat_id !== 0) {
      person_vat_id = this.state.person_vat_id;


    } else {
      // person_vat_id === 0
      if (Number(vat_buy) > 0){
        person_vat_id = 2
      } else {
        person_vat_id = 1
      }
    }*/


    // กรณี person_vat_id = 3 , รถฝากขายแล้วแก้ไขใส่ vat ซื้อภายหลัง
    if (car_cost > 0 && (vat_buy > 0)){
      person_vat_id = 2
    } else if (car_cost === 0  && vat_buy === 0) {
      if(PORT === 9048 ) {
        person_vat_id = 3
      }else {
        person_vat_id = 0
      }
    }else {
      person_vat_id = 1
    }

    if (PORT === 9043) {
      if (Number(vat_buy) > 0){
        person_vat_id = 2
      } else {
        person_vat_id = 1
      }
    }

    // console.log('person_vat_id=', person_vat_id, typeof person_vat_id)
    // return

    let cost_operation = this.state.cost_operation === '' ? 0 : this.state.cost_operation;
    let cost_service = this.state.cost_service === '' ? 0 : this.state.cost_service;
    let cost_transport = this.state.cost_transport === '' ? 0 : this.state.cost_transport;
    let cost_porobor_lan = this.state.cost_porobor_lan === '' ? 0 : this.state.cost_porobor_lan;
    let cost_bangkhab_phasi_lan = this.state.cost_bangkhab_phasi_lan === '' ? 0 : this.state.cost_bangkhab_phasi_lan;
    let cost_bangkhab_xon_lan = this.state.cost_bangkhab_xon_lan === '' ? 0 : this.state.cost_bangkhab_xon_lan;
    let cost_check_ton = this.state.cost_check_ton === '' ? 0 : this.state.cost_check_ton;

    axios.put(APIURL + '/car/car_price/'+car_id, {
      car_sale_price : car_sale_price,
      car_cost_tax : car_cost_tax,
      car_cost_act : car_cost_act,
      car_cost : car_cost,
      car_cost_repair : car_cost_repair,
      car_cost_commissions : car_cost_commissions,
      car_cost_expenses_other : car_cost_expenses_other,
      car_cost_recipe_other : car_cost_recipe_other,
      username: this.state.user.user,
      car_net_profit: car_net_profit,
      vat_buy: vat_buy,
      // at
      cost_operation : cost_operation,
      cost_service : cost_service,
      cost_transport : cost_transport,
      cost_porobor_lan : cost_porobor_lan,
      cost_bangkhab_phasi_lan : cost_bangkhab_phasi_lan,
      cost_bangkhab_xon_lan : cost_bangkhab_xon_lan,
      cost_check_ton : cost_check_ton,
      person_vat_id: person_vat_id
    }).then(res=>{

      if(res.status === 200){
         this.props.onLoadCarById();
         this.props.onToggle();
      }

    })
  }

  render(){
    let user_type = this.props.user_type
    let port = this.props.port

    return(
      <form action="" method="post" onSubmit={this.onCarEditPriceSubmit}>
        <div className="row" >
      <div className="col-sm-12">
        <div className="card card-header-warning">
          <div className="card-header card-edit">
             <strong className="text-title">แก้ไขราคาขาย/ค่าใช้จ่าย (ซื้อเข้า)</strong>
          </div>

           <div className="card-block">
            <div className="row">
              <div className="col-sm-12">

                <div className="row  mb-1" style={styles.bottomLine}>

                  <div className={port === 9029 && user_type === 'manager' ? 'hidden' : "form-group col-sm-12" }  >
                    <label htmlFor="sale-price">ราคาขาย</label>
                    <input type="text"
                           className="form-control hidden"
                           name="car_sale_price"
                           placeholder=""
                           ref="car_sale_price"
                           value={this.state.car_sale_price}
                           onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ราคาขาย"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.car_sale_price}
                        onValueChange = {(values) => {
                          const {value} = values
                          this.setState({car_sale_price: value},()=>{
                            this.handleOnChange()
                          })

                        }}
                    />
                  </div>

                </div>
                <div className="row">



                  <div className="form-group col-sm-6">
                    <label htmlFor="cost">ราคาซื้อ (ไม่รวม VAT)</label>
                    <input type="text"
                           className="form-control hidden "
                           name="car_cost"
                           placeholder=""
                           ref="car_cost"
                           value={this.state.car_cost}
                           onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ราคาซื้อ"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost: value},()=>{
                          this.handleOnChange();
                        })

                      }}
                    />
                  </div>


                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-tax">ภาษีซื้อ (VAT ซื้อ)&nbsp;</label>
                      <button type="button" onClick={() => {
                        let car_cost = this.state.car_cost === '' ? 0 : Number(this.state.car_cost);
                        let vat = car_cost * (0.07);
                        let vat_fix = parseFloat(String(vat)).toFixed(2);

                        this.setState({
                          vat_buy : vat_fix
                        });

                      }} >คำนวณ</button>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="vat_buy"
                      placeholder=""
                      ref="vat_buy"
                      value={this.state.vat_buy}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ภาษีซื้อ(Vat ซื้อ)"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.vat_buy}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({vat_buy: value},()=>{
                          //this.handleOnChange()
                        })

                      }}
                    />
                  </div>


                  {/*<div className="form-group col-sm-6">
                    <label htmlFor="cost-repair">ค่าซ่อมบำรุง</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_repair"
                      placeholder=""
                      ref="car_cost_repair"
                      value={this.state.car_cost_repair}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าซ่อมบำรุง"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_repair}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_repair: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>*/}


                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-act">ค่าใช้จ่าย พ.ร.บ. </label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_act"
                      placeholder=""
                      ref="car_cost_act"
                      value={this.state.car_cost_act}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าใช้จ่าย พ.ร.บ."
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_act}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_act: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-tax">ค่าภาษีรถยนต์</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_tax"
                      placeholder=""
                      ref="car_cost_tax"
                      value={this.state.car_cost_tax}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าภาษีรถยนต์"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_tax}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_tax: value},()=>{
                          this.handleOnChange()
                        })

                      }}
                    />
                  </div>



                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-commissions">ค่าคอมมิชชั่น/ค่าแนะนำซื้อรถเข้า</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_commissions"
                      placeholder=""
                      ref="car_cost_commissions"
                      value={this.state.car_cost_commissions}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าคอมมิชชั่น"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_commissions}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_commissions: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-6">

                  </div>


                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_operation">ค่าดำเนินการ</label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่าดำเนินการ"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_operation}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_operation: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_service">ค่าบริการ</label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่าบริการ"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_service}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_service: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_transport">ค่าขนย้าย</label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่าขนย้าย"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_transport}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_transport: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_porobor_lan">ค่า พรบ. จากลาน </label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่า พรบ. จากลาน "
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_porobor_lan}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_porobor_lan: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_bangkhab_phasi_lan">ค่าบังคับต่อภาษี จากลาน </label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่าบังคับต่อภาษี จากลาน "
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_bangkhab_phasi_lan}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_bangkhab_phasi_lan: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_bangkhab_xon_lan">
                      {PORT === 9043 ? 'ค่าบังคับโอนเข้า A.T. จากลาน': 'ค่าบังคับโอนเข้าเต็นท์จากลาน'}
                    </label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่าบังคับโอนเข้าเต็นท์จากลาน"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_bangkhab_xon_lan}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_bangkhab_xon_lan: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_check_ton">ค่าเช็คต้น</label>
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="ค่าเช็คต้น"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.cost_check_ton}
                        onValueChange = {(values) => {
                          const {value} = values;
                          this.setState({cost_check_ton: value},()=>{
                            this.handleOnChange()
                          })
                        }}
                    />
                  </div>

                  {/*<div className="form-group col-sm-6">
                    <label htmlFor="cost-expenses-other">ค่าใช้จ่ายอื่นๆ</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_expenses_other"
                      placeholder=""
                      ref="car_cost_expenses_other"
                      value={this.state.car_cost_expenses_other}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าใช้จ่ายอื่นๆ"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_expenses_other}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_expenses_other: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>*/}

                 {/* <div className="form-group col-sm-6">
                    <label htmlFor="cost-recipe-other">รายรับอื่นๆจากการขาย</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_recipe_other"
                      placeholder=""
                      ref="car_cost_recipe_other"
                      value={this.state.car_cost_recipe_other}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="รายรับอื่นๆจากการขาย"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_recipe_other}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_recipe_other: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>*/}

                  <div className="form-group has-warning col-sm-12">
                    <label htmlFor="net-profit">กำไรสุทธิ</label>
                    <input type="text"
                           className="form-control hidden"
                           name="car_net_profit"
                           placeholder="กำไรสุทธิ"
                           ref="car_net_profit"
                           value={this.state.car_net_profit}
                           onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="กำไรสุทธิ"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_net_profit}
                      displayType="text"
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div className="card-footer text-right">
            <button type="reset"
                    className="btn btn-sm btn-danger mr-2"
                    onClick={this.props.onToggle}
            >
              <i className="icon-close"/> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary">
              <i className="fa fa-save"/> บันทึก
            </button>
          </div>

        </div>
      </div>
        </div>
      </form>
    )
  }


}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})
export default connect(mapStateToProps)(CarEditPrice);
