import React, { Component } from 'react'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import {
  APIURL,
  IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, IMAGE_PDF_URL,
  IMAGE_UPLOAD_ERROR,
  PORT,
  // IMAGE_THUMB_URL
} from '../../../config/config'
import { connect } from 'react-redux'
import MaskedInput  from 'react-maskedinput'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import NumberFormat from 'react-number-format'
import 'moment/locale/th'
import Loading from '../../Loading'
import { AlertError, AlertWarning } from '../../Alert/Alert'
import { checkGroupPermission, getGroupPermission } from '../../../services/grouppermission'
import ReactQuill from 'react-quill'
import { CarUtil } from '../../../utils/carUtil'
import DocUtil from '../../../utils/docUtil'
import BDatePicker from '../../BDatePicker'

moment.locale('th')

const randomString = require('random-string')
// const port = window.env.PORT;
// 110 ตำหนิรถ
class CarNew extends Component {

  constructor (props) {
    super(props)

    this.state = {
      lists: [],
      car_brands: [],
      car_models: [],
      car_sub_models: [],
      car_years: [],
      car_colors: [],
      car_gears: [],
      car_status: [],
      car_name_owner_old: [],
      province: [],
      branch: [],
      car_hassock: [],
      car_system_drive: [],
      car_type_engine: [],
      car_type: [],
      car_structure: [],
      load_data: true,
      danger: false,
      car_id: 0,
      action: 'บันทึก',
      car: '',
      upload_code: '',
      // startDateCarNew1: moment().format('YYYY-MM-DD'),
      startDateCarNew2: null,
      startDateCarNew3: null,
      car_date_in: null,
      car_cost: 0,
      car_sale_price: 0,
      car_cost_repair: 0,
      car_cost_tax: 0,
      car_cost_act: 0,
      car_cost_commissions: 0,
      car_cost_expenses_other: 0,
      car_cost_recipe_other: 0,
      car_net_profit: 0,
      user: {},
      car_name: '',
      car_detail: '',
      tmp_car_brand: '',
      tmp_car_model: '',
      tmp_car_sub_model: '',
      tmp_car_year: '',
      tmp_car_gear: '',
      tmp_car_color: '',
      car_miles: 0,
      //
      floorplan_credit: 0,
      banks: [],
      insurance_companies: [],
      insurance_types: [],
      floorplan_start_date: null,
      floorplan_due_date: null,
      floorplan_annual_interest : 0,
      receipt_date: null,
      //
      form_controls: [],
      insurance_end_date: null,
      compulsory_insurance_end_date: null,
      isLoading: false,
      // id
      front_car_id: 0,
      front_right_id: 0,
      front_left_id: 0,
      side_car_left_id: 0,
      side_car_right_id: 0,
      back_straight_id: 0,
      condition_in_car_id: 0,
      engine_car_id: 0,
      console_car_id: 0,
      plate_car_id: 0,
      other_car_id: 0,
      console_id: 0,
      gear_id: 0,
      audio_equipment_id: 0,
      dial_id: 0,
      catalyticconverter_id: 0,
      yearoftire_id: 0,
      chassis_id: 0,
      other1_id: 0,
      other2_id: 0,
      other3_id: 0,
      plate_id: 0,
      engine_oil_id: 0,
      front_left_tire_number_id: 0,
      front_right_tire_number_id: 0,
      back_left_tire_number_id: 0,
      back_right_tire_number_id: 0,
      car_key_id: 0,
      cover_car_id: 0,

      park_location: '',
      car_parking_list: [],
      // set 2
      front_car2_id: 0,
      front_right2_id: 0,
      front_left2_id: 0,
      side_car_left2_id: 0,
      side_car_right2_id: 0,
      back_straight2_id: 0,
      console_car2_id: 0,
      condition_in_car2_id: 0,
      engine_car2_id: 0,
      plate_car2_id: 0,
      other_car2_id: 0,
      logo_image: IMAGE_NOPHOTO_URL,
      buy_car_from: '',
      menuID: [106, 110],
      grouppermissions: [],
      web_info: '',
      code_item : null,
      car_feature_other: '',
      vat_buy: 0,
      company_setting: null,
      is_vat: null,
      vatPercent: null,
      // at
      cost_operation: 0, // ค่าดำเนินการ
      cost_service: 0, // ค่าบริการ
      cost_transport: 0, // ค่าขนย้าย
      cost_porobor_lan: 0, // ค่า พรบ. จากลาน
      cost_bangkhab_phasi_lan: 0, // ค่าบังคับต่อภาษี จากลาน
      cost_bangkhab_xon_lan: 0, // ค่าบังคับโอนเข้า A.T. จากลาน
      cost_check_ton: 0, // ค่าเช็คต้น
      //
      car_blames: CarUtil.getCarBlamesInit('car_new'),
      car_license_plate_old: '',
      repeat_license_old: 'noset',
      repeat_license_old_text: '',
      // at เลขไมล์
      car_miles_center: '',
      car_miles_center_date: null,
      car_miles_status: '',
      is_date_fill: 0,
      warantee_date_end: null,
      car_status_id : '6',
    }

    this.handleOnSubmitCar = this.handleOnSubmitCar.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.handleUploadFile2 = this.handleUploadFile2.bind(this)
    this.handleDateCarNew1 = this.handleDateCarNew1.bind(this)
    this.handleDateCarNew2 = this.handleDateCarNew2.bind(this)
    this.handleDateCarNew3 = this.handleDateCarNew3.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)

    this.handleCarSubModelChange = this.handleCarSubModelChange.bind(this)
    this.handleCarNameChange = this.handleCarNameChange.bind(this)
    this.handleCarGearChange = this.handleCarGearChange.bind(this)
    this.handleCarColorChange = this.handleCarColorChange.bind(this)
    this.handleCarDetailChange = this.handleCarDetailChange.bind(this)
    this.handleCarFeatureCheck = this.handleCarFeatureCheck.bind(this)

    this.convertBBtoCC = this.convertBBtoCC.bind(this)
    this.onMaskedChange1 = this.onMaskedChange1.bind(this)
    this.onMaskedChange2 = this.onMaskedChange2.bind(this)
    this.onMaskedChange3 = this.onMaskedChange3.bind(this)

    this.handleOnChangeNoSet = this.handleOnChangeNoSet.bind(this)
    this.handleCarDateIn = this.handleCarDateIn.bind(this)
    this.loadBanks = this.loadBanks.bind(this)
    this.loadInsuranceCompanies = this.loadInsuranceCompanies.bind(this)
    this.loadInsuranceType = this.loadInsuranceType.bind(this)
    this.handleFloorplanStartDate = this.handleFloorplanStartDate.bind(this)
    this.handleFloorplanDueDate = this.handleFloorplanDueDate.bind(this)
    this.handleReceiptDate = this.handleReceiptDate.bind(this)
    //
    this.loadFormControls = this.loadFormControls.bind(this)
    this.handleInsuranceEndDate = this.handleInsuranceEndDate.bind(this)

    this.loadCarParking = this.loadCarParking.bind(this)
    this.loadLoginInfo = this.loadLoginInfo.bind(this)
    this.loadGroupPermission = this.loadGroupPermission.bind(this)
    this.loadCompanySetting = this.loadCompanySetting.bind(this)
    this.renderCarBlames = this.renderCarBlames.bind(this)
    this.handleUploadFile3 = this.handleUploadFile3.bind(this)
    this.checkCarLicenseOld = this.checkCarLicenseOld.bind(this)
    this.checkCarLicenseOldClass = this.checkCarLicenseOldClass.bind(this)
  }

  renderCarBlames(car_blame) {
    // no, name, label, src, car_upload_id
    let no = car_blame.no
    let name = car_blame.name
    let label = car_blame.label
    let src = car_blame.src
    let car_upload_id = car_blame.car_upload_id

    return <div key={no} className="form-group col-sm-2">
      <label htmlFor="name">{no}) {label}</label>
      <div style={styles.fileInputWrapper}>
        <button style={styles.btnFileInput}>
          <img src={src}
               style={styles.btnFileInputImage}
               alt={label}
          />
        </button>
        <input
          style={styles.fileInput}
          type="file" name={name}
          onChange={(e) => this.handleUploadFile3(e, no, car_upload_id)}
        />
      </div>
    </div>
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  handleOnSubmitCar (event) {
    event.preventDefault()

    let car_upload_code = this.state.upload_code
    let car_owner_old_order = event.target.car_owner_old_order.value
    let car_name_owner_old = event.target.car_name_owner_old.value
    let car_lastname_owner_old = event.target.car_lastname_owner_old.value
    let car_id_card = event.target.car_id_card.value
    let car_license_plate_old = event.target.car_license_plate_old.value
    let province_old_id = event.target.province_old_id.value
    let car_telephone_old = event.target.car_telephone_old.value
    let car_address_old = event.target.car_address_old.value
    let car_other = event.target.car_other.value
    let branch_id = event.target.branch_id.value
    //let car_date_in = event.target.car_date_in.value
    //let car_date_in = this.state.startDateCarNew1
    let car_date_in = this.state.car_date_in
    let car_name = event.target.car_name.value
    let car_detail = event.target.car_detail.value
    let car_brand_id = event.target.car_brand_id.value
    let car_model_id = event.target.car_model_id.value
    let car_sub_model_id = event.target.car_sub_model_id.value
    let car_year_id = event.target.car_year_id.value
    let car_color_id = event.target.car_color_id.value
    let car_gear_id = event.target.car_gear_id.value
    let car_license_plate_new = event.target.car_license_plate_new.value
    let province_new_id = event.target.province_new_id.value
    let car_miles = event.target.car_miles.value
    let car_number = event.target.car_number.value
    car_number = car_number.toUpperCase()
    let car_num_engines = event.target.car_num_engines.value
    car_num_engines = car_num_engines.toUpperCase()
    //let car_date_regis = event.target.car_date_regis.value

    let car_date_regis = event.target.car_date_regis_text.value === "" ? "" : this.state.startDateCarNew3
    let car_date_tax = event.target.car_date_tax_text.value === "" ? "" : this.state.startDateCarNew2

    let car_type_id = event.target.car_type_id.value
    let car_structure_id = event.target.car_structure_id.value
    let car_hassock_id = event.target.car_hassock_id.value
    let car_system_drive_id = event.target.car_system_drive_id.value
    let car_type_engine_id = event.target.car_type_engine_id.value
    let car_brand_engine = event.target.car_brand_engine.value
    let car_gas_number = event.target.car_gas_number.value
    let car_weight = event.target.car_weight.value
    let car_size_engine = event.target.car_size_engine.value
    //let car_date_tax = event.target.car_date_tax.value

    let car_tax = event.target.car_tax.value
    let car_status_id = event.target.car_status_id.value
    let car_cost = event.target.car_cost.value === '' ? 0 : Number(event.target.car_cost.value)
    let car_sale_price = event.target.car_sale_price.value
    let car_cost_repair = event.target.car_cost_repair.value
    let car_cost_tax = event.target.car_cost_tax.value
    let car_cost_act = event.target.car_cost_act.value
    let car_cost_commissions = event.target.car_cost_commissions.value
    let car_cost_expenses_other = event.target.car_cost_expenses_other.value
    let car_cost_recipe_other = event.target.car_cost_recipe_other.value
    let car_net_profit = event.target.car_net_profit.value
    //pisan
    let liciense_status_id = event.target.liciense_status_id.value
    let finance_id = event.target.finance_id.value
    let floorplan_credit = event.target.floorplan_credit.value
    let finance_floorplan_id = event.target.finance_floorplan_id.value
    let insurance_type_id = event.target.insurance_type_id.value
    let insurance_company_id = event.target.insurance_company_id.value
    let floorplan_start_date = event.target.floorplan_start_date.value
    let floorplan_due_date = event.target.floorplan_due_date.value
    let floorplan_annual_interest = event.target.floorplan_annual_interest.value
    let receipt_date = event.target.receipt_date.value
    let deposit_registration_id = event.target.deposit_registration_id.value
    //
    let insurance_end_date = event.target.insurance_end_date.value
    let compulsory_insurance = event.target.compulsory_insurance.value
    let compulsory_insurance_company = event.target.compulsory_insurance_company.value

    let compulsory_insurance_end_date = this.state.compulsory_insurance_end_date
    let car_spare_key = event.target.car_spare_key.value
    let car_manual = event.target.car_manual.value
    let park_location = event.target.park_location.value
    let buy_car_from = event.target.buy_car_from.value
    let web_info = this.state.web_info

    //kmhome //thadyon
    let code_item = this.state.code_item
    let car_feature_other = this.state.car_feature_other

    // atcarsale
    let cost_operation = this.state.cost_operation
    let cost_service = this.state.cost_service
    let cost_transport = this.state.cost_transport
    let cost_porobor_lan = this.state.cost_porobor_lan
    let cost_bangkhab_phasi_lan = this.state.cost_bangkhab_phasi_lan
    let cost_bangkhab_xon_lan = this.state.cost_bangkhab_xon_lan
    let cost_check_ton = this.state.cost_check_ton
    let vat_buy = this.state.vat_buy === '' ? 0 : Number(this.state.vat_buy)
    // at car_book_service
    let car_miles_center = this.state.car_miles_center
    let car_miles_center_date = this.state.car_miles_center_date
    let car_miles_status = event.target.car_miles_status.value
    let car_book_service = event.target.car_book_service.value

    let car_grade = event.target.car_grade.value
    let warantee_date_end = this.state.warantee_date_end
    let cover_car_id = this.state.cover_car_id

    if (PORT === 9047) {
      if (Number(cover_car_id) === 0) {
        AlertError('กรุณาเพิ่มภาพปกรถ');
        return
      }
    }

    //console.log('car_year=', car_year_id, typeof car_year_id)
    // console.log('warantee_date_end=', warantee_date_end)
    // return

    let person_vat_id = 0
    if(car_cost > 0 && vat_buy === 0){
      // 1 = รถบ้าน
      person_vat_id = 1
    }else if (car_cost > 0 && (vat_buy > 0)){
      // 2 = รถ VAT
      person_vat_id = 2
    }else if (car_cost === 0  && vat_buy === 0) {
      // 3 = รถฝากขาย
      if (PORT === 9048){
        person_vat_id = 3
      }else {
        person_vat_id = 0
      }
    }

    if (PORT === 9043) {
      if (Number(vat_buy) > 0){
        person_vat_id = 2
      } else {
        person_vat_id = 1
      }
    }

    // console.log('ลักษณะ car_structure_id =', car_structure_id)
    // console.log('ระบบขับเคลื่อน car_system_drive_id =', car_system_drive_id)
    // return;

    // console.log('car_owner_old_order=', car_owner_old_order)
    // return

    let img_front_car_url = this.refs.img_front_car.src

    if(car_date_in === null) {
      AlertError('กรุณาระบุวันที่ซื้อเข้า')
      return
    }

    if (car_name_owner_old === '') {
      AlertWarning('กรุณากรอกข้อมูลเจ้าของเดิม')
      this.refs.car_name_owner_old.focus()
      return
    }

    if (car_license_plate_old === '') {
      AlertWarning('กรุณากรอกป้ายทะเบียนเดิม')
      this.refs.car_license_plate_old.focus()
      return
    }

    if (branch_id === '') {
      AlertWarning('กรุณาเลือกสาขาที่รถอยู่')
      this.refs.branch_id.focus()
      return
    }

    if (car_brand_id === '') {
      AlertWarning('กรุณาเลือกยี่ห้อรถ')
      this.refs.car_brand_id.focus()
      return
    }

    if (car_model_id === '') {
      AlertWarning('กรุณาเลือกรุ่นรถ')
      return
    }
    if (car_sub_model_id === '') {
      AlertWarning('กรุณาเลือกรุ่นย่อย')
      return
    }

    if(PORT === 9048 && buy_car_from === '') {
      AlertWarning('กรุณากรอกข้อมูลซื้อรถจาก')
      this.refs.buy_car_from.focus()
      return
    }

    if(PORT === 9048 && car_color_id === '') {
      AlertWarning('กรุณาเลือกสีรถ')
      return
    }

    if(PORT === 9048 && car_year_id === '23') {
      AlertWarning('กรุณาเลือกปีรถ')
      return
    }

    if(PORT === 9048 && car_number === '') {
      AlertWarning('กรุณากรอกเลขตัวรถ')
      this.refs.car_number.focus()
      return
    }

    if(PORT === 9048 && car_num_engines === '') {
      AlertWarning('กรุณากรอกเลขเครื่องยนต์')
      this.refs.car_num_engines.focus()
      return
    }

    let car_front_url = ''
    if(img_front_car_url.indexOf('add_image') >= 0){
      AlertWarning('กรุณาเลือกภาพหน้ารถ')
      return
      // TODO: Add Car No Image
      // car_front_url = this.state.logo_image
    }

    this.setState({
      isLoading: true
    })

    axios.post(APIURL + '/car/', {
      car_upload_code: car_upload_code,
      car_front_url: car_front_url,
      car_name_owner_old: car_name_owner_old,
      car_lastname_owner_old: car_lastname_owner_old,
      car_id_card: car_id_card,
      car_license_plate_old: car_license_plate_old,
      province_old_id: province_old_id,
      car_telephone_old: car_telephone_old,
      car_address_old: car_address_old,
      car_other: car_other,
      branch_id: branch_id,
      car_date_in: car_date_in,
      car_name: car_name,
      car_detail: car_detail,
      car_brand_id: car_brand_id,
      car_model_id: car_model_id,
      car_sub_model_id: car_sub_model_id,
      car_gear_id: car_gear_id,
      car_color_id: car_color_id,
      car_year_id: car_year_id,
      car_license_plate_new: car_license_plate_new,
      province_new_id: province_new_id,
      car_miles: car_miles,
      car_number: car_number,
      car_num_engines: car_num_engines,
      car_date_regis: car_date_regis,
      car_type_id: car_type_id,
      car_structure_id: car_structure_id,
      car_hassock_id: car_hassock_id,
      car_system_drive_id: car_system_drive_id,
      car_type_engine_id: car_type_engine_id,
      car_brand_engine: car_brand_engine,
      car_gas_number: car_gas_number,
      car_weight: car_weight,
      car_size_engine: car_size_engine,
      car_date_tax: car_date_tax,
      car_tax: car_tax,
      car_status_id: car_status_id,
      car_cost: car_cost,
      car_sale_price: car_sale_price,
      car_cost_repair: car_cost_repair,
      car_cost_tax: car_cost_tax,
      car_cost_act: car_cost_act,
      car_cost_commissions: car_cost_commissions,
      car_cost_expenses_other: car_cost_expenses_other,
      car_cost_recipe_other: car_cost_recipe_other,
      car_net_profit: car_net_profit,
      user_created: this.state.user.user,
      user_updated: this.state.user.user,
      //
      liciense_status_id: liciense_status_id,
      finance_id: finance_id,
      floorplan_credit: floorplan_credit,
      finance_floorplan_id: finance_floorplan_id,
      insurance_type_id: insurance_type_id,
      insurance_company_id: insurance_company_id,
      floorplan_start_date: floorplan_start_date,
      floorplan_due_date: floorplan_due_date,
      floorplan_annual_interest: floorplan_annual_interest,
      receipt_date: receipt_date,
      deposit_registration_id: deposit_registration_id,
      //
      insurance_end_date : insurance_end_date,
      compulsory_insurance : compulsory_insurance,
      compulsory_insurance_company : compulsory_insurance_company,
      compulsory_insurance_end_date : compulsory_insurance_end_date,
      car_spare_key : car_spare_key,
      car_manual : car_manual,
      park_location : park_location,
      buy_car_from: buy_car_from,
      web_info: web_info,
      //
      code_item : code_item,
      car_feature_other: car_feature_other,
      // AT
      cost_operation : cost_operation,
      cost_service : cost_service,
      cost_transport : cost_transport,
      cost_porobor_lan : cost_porobor_lan,
      cost_bangkhab_phasi_lan : cost_bangkhab_phasi_lan,
      cost_bangkhab_xon_lan : cost_bangkhab_xon_lan,
      cost_check_ton : cost_check_ton,
      vat_buy : vat_buy,
      person_vat_id : person_vat_id,
      car_miles_center ,
      car_miles_center_date ,
      car_miles_status ,
      car_book_service ,
      car_owner_old_order,
      car_grade,
      warantee_date_end
    })
      .then(res => {
        if(res.status === 200){

          //CarUtil.removeCarSelect();
          //console.log('removeCarSelect')

          this.setState({
            isLoading: false
          }, () => this.props.history.push('/cars/list/1'))
        }
      })
      .catch(error => {
        console('error:' + error)
      })
  }

  clearForm (event) {
    event.target.car_name_owner_old.value = ''
    event.target.car_lastname_owner_old.value = ''
    event.target.car_id_card.value = ''
    event.target.car_license_plate_old.value = ''
    event.target.province_old_id.value = ''
    event.target.car_telephone_old.value = ''
    event.target.car_address_old.value = ''
    event.target.car_other.value = ''
    event.target.branch_id.value = ''
    //event.target.car_date_in.value = ""
    event.target.car_name.value = ''
    event.target.car_detail.value = ''
    event.target.car_brand_id.value = ''
    event.target.car_model_id.value = ''
    event.target.car_sub_model_id.value = ''
    event.target.car_year_id.value = ''
    event.target.car_color_id.value = ''
    event.target.car_gear_id.value = ''
    event.target.car_license_plate_new.value = ''
    event.target.province_new_id.value = ''
    event.target.car_miles.value = ''
    event.target.car_number.value = ''
    event.target.car_num_engines.value = ''
    //event.target.car_date_regis.value = ""
    event.target.car_type_id.value = ''
    event.target.car_structure_id.value = ''
    event.target.car_hassock_id.value = ''
    event.target.car_system_drive_id.value = ''
    event.target.car_type_engine_id.value = ''
    event.target.car_brand_engine.value = ''
    event.target.car_gas_number.value = ''
    event.target.car_weight.value = ''
    event.target.car_size_engine.value = ''
    //event.target.car_date_tax.value = ""
    event.target.car_tax.value = ''
    event.target.car_status_id.value = ''
    event.target.car_cost.value = ''
    event.target.car_sale_price.value = ''
    event.target.car_cost_repair.value = ''
    event.target.car_cost_tax.value = ''
    event.target.car_cost_act.value = ''
    event.target.car_cost_commissions.value = ''
    event.target.car_cost_expenses_other.value = ''
    event.target.car_cost_recipe_other.value = ''
    event.target.car_net_profit.value = ''
    //floorplan
    event.target.floorplan_annual_interest.value = ''
    //
    event.target.code_item.value = ''
  }

  handleCarBrandChange (event) {
    let car_brand_id = event.target.value
    let car_brand_name = event.target.options[event.target.selectedIndex].text
    let that = this

    axios.get(APIURL + '/car_brand_model/' + car_brand_id)
      .then(res => {
        that.setState({
          car_models: res.data,
          tmp_car_brand: car_brand_name,
          tmp_car_model: '',
          tmp_car_sub_model: '',
        })
        that.makeCarName()
      }).catch(error => {
      that.setState({car_models: []})
    })
  }

  makeCarName () {
    let brand = this.state.tmp_car_brand
    let model = this.state.tmp_car_model
    let sub_model = this.state.tmp_car_sub_model
    let year = this.state.tmp_car_year
    let car_name = ''

    if (sub_model !== '') {
      if (sub_model.includes(model)) {
        car_name = brand + ' ' + sub_model
      } else {
        car_name = brand + ' ' + model + ' ' + sub_model
      }
    } else {
      car_name = brand + ' ' + model + ' ' + sub_model
    }

    if (year !== '') {
      car_name += ' ปี ' + year
    }

    this.setState({
      car_name: car_name
    })
  }

  makeCarDetail () {
    let brand = this.state.tmp_car_brand
    let model = this.state.tmp_car_model
    let sub_model = this.state.tmp_car_sub_model
    let year = this.state.tmp_car_year
    let gear = this.state.tmp_car_gear
    let color = this.state.tmp_car_color
    //var car_detail = brand+" "+model+" "+sub_model

    let car_detail = ''

    if (sub_model !== '') {
      if (sub_model.includes(model)) {
        car_detail = brand + ' ' + sub_model
      } else {
        car_detail = brand + ' ' + model + ' ' + sub_model
      }
    } else {
      car_detail = brand + ' ' + model + ' ' + sub_model
    }

    if (year !== '')
      car_detail += ' ปี ' + year

    if (gear !== '')
      car_detail += ' ' + gear

    if (color !== '')
      car_detail += ' สี ' + color

    this.setState({
      car_detail: car_detail
    })

  }

  handleCarModelChange (event) {
    let car_model_id = event.target.value
    let car_model = event.target.options[event.target.selectedIndex].text
    let that = this
    axios.get(APIURL + '/car_sub_model/' + car_model_id)
      .then(res => {
        that.setState({car_sub_models: res.data, tmp_car_model: car_model})
        that.makeCarName()

      }).catch(error => {
      that.setState({car_sub_models: []})
    })
  }

  handleCarSubModelChange (event) {
    let car_sub_model = event.target.options[event.target.selectedIndex].text
    this.setState({
        tmp_car_sub_model: car_sub_model
      },
      () => {
        this.makeCarName()
      })

  }

  handleCarYearChange (event) {
    let car_year = event.target.options[event.target.selectedIndex].text
    this.setState({tmp_car_year: car_year}, () => {
      this.makeCarName()
      this.makeCarDetail()
    })
  }

  handleCarNameChange () {
    let car_name = this.refs.car_name.value
    this.setState({
      car_name: car_name
    })
  }

  handleCarDetailChange () {
    let car_detail = this.refs.car_detail.value
    this.setState({
      car_name: car_detail
    })
  }

  handleCarGearChange (event) {
    this.setState({tmp_car_gear: event.target.options[event.target.selectedIndex].text}, () => {
      this.makeCarDetail()
    })
  }

  handleCarColorChange (event) {
    this.setState({tmp_car_color: event.target.options[event.target.selectedIndex].text}, () => {
      this.makeCarDetail()
    })
  }

  handleDateCarNew1 (date) {
    //alert (date)
    /*this.setState({
      startDateCarNew1: moment(date).format('YYYY-MM-DD')
    })*/
  }

  handleDateCarNew2 (date) {

    this.setState({
      startDateCarNew2: moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null
    })

  }

  handleDateCarNew3 (date) {
    //let datebuddha =   new Date(date).add(Date.YEAR , 543);
    //alert (datebuddha);

      this.setState({
        startDateCarNew3: moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null
      })


  }

  onMaskedChange1 (e) {
    let buddhist_date = e.target.value
    //alert('buddhist_date='+buddhist_date);
    if (buddhist_date.length === 10) {
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateCarNew1(cc_date)
    }
  }

  onMaskedChange2 (e) {
    let buddhist_date = e.target.value
    //alert('buddhist_date='+buddhist_date);
    if (buddhist_date.length === 10) {
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateCarNew2(cc_date)
    }
  }

  onMaskedChange3 (e) {
    let buddhist_date = e.target.value
    //alert('buddhist_date='+buddhist_date);
    if (buddhist_date.length === 10) {
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateCarNew3(cc_date)
    }
  }

  convertBBtoCC (buddhist_date) { //dd-mm-yyyy to yyyy-mm-dd
    let date_arr = buddhist_date.split('-')
    let dd = date_arr[0]
    let mm = date_arr[1]
    let yyyy = date_arr[2] - 543
    let cc_date = yyyy + '-' + mm + '-' + dd
    if (moment(cc_date, 'YYYY-MM-DD', true).isValid()) {
      //alert(cc_date);
      return cc_date
    }
    return moment()
  }

  handleCarFeatureCheck (event) {
    this.setState({checked: !this.state.checked});
  }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
      .then(res => {
        this.setState({car_brands: res.data})
      }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  loadCarYearData () {
    axios.get(APIURL + '/car_year/')
      .then(res => {
        this.setState({car_years: res.data})
      }).catch(error => {
      this.setState({car_years: []})
    })
  }

  loadCarColorData () {
    axios.get(APIURL + '/car_color/')
      .then(res => {
        this.setState({car_colors: res.data})
      }).catch(error => {
      this.setState({car_colors: []})
    })
  }

  loadCarGearData () {
    axios.get(APIURL + '/car_gear/')
      .then(res => {
        this.setState({car_gears: res.data})
      }).catch(error => {
      this.setState({car_gears: []})
    })
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/new')
      .then(res => {
        this.setState({car_status: res.data})
      }).catch(error => {
      this.setState({car_status: []})
    })
  }

  loadProvinceData () {
    axios.get(APIURL + '/province/')
      .then(res => {
        this.setState({province: res.data})
      }).catch(error => {
      this.setState({province: []})
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branch: res.data})
      }).catch(error => {
      this.setState({branch: []})
    })
  }

  loadCarHassockData () {
    axios.get(APIURL + '/car_hassock/')
      .then(res => {
        this.setState({car_hassock: res.data})
      }).catch(error => {
      this.setState({car_hassock: []})
    })
  }

  loadCarSystemDriveData () {
    axios.get(APIURL + '/car_system_drive/')
      .then(res => {
        this.setState({car_system_drive: res.data})
      }).catch(error => {
      this.setState({car_system_drive: []})
    })
  }

  loadCarTypeEngineData () {
    axios.get(APIURL + '/car_type_engine/')
      .then(res => {
        this.setState({car_type_engine: res.data})
      }).catch(error => {
      this.setState({car_type_engine: []})
    })
  }

  loadCarTypeData () {
    axios.get(APIURL + '/car_type/')
      .then(res => {
        this.setState({car_type: res.data})
      }).catch(error => {
      this.setState({car_type: []})
    })
  }

  loadCarStructureData () {
    axios.get(APIURL + '/car_structure/')
      .then(res => {
        this.setState({car_structure: res.data})
      }).catch(error => {
      this.setState({car_structure: []})
    })
  }

  loadData () {
    axios.get(APIURL + '/car/')
      .then(res => {
        //alert("res"+res);
        this.setState({lists: res.data,})
      }).catch(error => {
      //alert("error"+error.message);
      this.setState({lists: []})
    })
  }

  loadBanks () {
    axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({banks: res.data})
      }).catch(error => {
      this.setState({banks: []})
    })
  }

  loadInsuranceCompanies() {
    axios.get(APIURL + '/insurance_company/')
      .then(res => {
        this.setState({insurance_companies: res.data})
      }).catch(error => {
      this.setState({insurance_companies: []})
    })
  }

  //insurance_type
  loadInsuranceType() {
    axios.get(APIURL + '/insurance_type/')
      .then(res => {
        this.setState({insurance_types: res.data})
      }).catch(error => {
      this.setState({insurance_types: []})
    })
  }

  loadFormControls() {
    let tableName = "car";
    axios.get(APIURL + '/form_controls/'+tableName)
      .then(res => {
        this.setState({form_controls: res.data});
      }).catch(error => {
      this.setState({form_controls: []})
    })
  }


  /*shouldComponentUpdate(nextProps) {
   return this.props.user !== nextProps.user;
   }*/

  async componentDidMount () {
    // if(this.state.load_data)
    await this.loadCompanySetting();
    this.loadGroupPermission()
    this.loadFormControls()
    this.loadData()
    this.loadCarBrandData()
    this.loadCarYearData()
    this.loadCarColorData()
    this.loadCarGearData()
    this.loadCarStatusData()
    this.loadProvinceData()
    this.loadBranchData()
    this.loadCarHassockData()
    this.loadCarSystemDriveData()
    this.loadCarTypeEngineData()
    this.loadCarTypeData()
    this.loadCarStructureData()
    this.loadBanks()
    this.loadInsuranceType()
    this.loadInsuranceCompanies()
    this.loadCarParking()

    if (this.state.upload_code === '') {
      let random_code = randomString({
        length: 28,
        numeric: true,
        letters: true,
        special: false,
        exclude: ['o', '0', 'l']
      })

      this.setState({upload_code: random_code}, ()=>{
        this.loadLoginInfo()
      })
    }
    this.initFormValue()

  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      const data = res.data;
      const company_setting = data.company_setting;
      const is_vat = company_setting.is_vat;
      const is_date_fill = company_setting.is_date_fill;

      const vatPercent = is_vat === 1 ? company_setting.vat/100.0 : 0;
      this.setState({
        company_setting: company_setting,
        is_vat,
        vatPercent,
        is_date_fill: is_date_fill
      });
    })
  }

  loadLoginInfo() {
    axios.get(APIURL + '/signin/info').then(res=> {
      let data = res.data
      let company_setting = data.company_setting

      if (company_setting !== null) {
        this.setState({
          logo_image: company_setting.logo_image === undefined ? '': company_setting.logo_image,
        })
      }

    }).catch(err => {
      console.log('err:', err)
    })
  }

  initFormValue () {
    /*this.refs.car_cost.value = 0
     this.refs.car_sale_price.value = 0
     this.refs.car_cost_repair.value = 0
     this.refs.car_cost_tax.value = 0
     this.refs.car_cost_act.value = 0
     this.refs.car_cost_commissions.value = 0
     this.refs.car_cost_expenses_other.value = 0
     this.refs.car_net_profit.value = 0
     this.refs.car_cost_recipe_other.value = 0*/
  }

  async handleUploadFile3 (event, no, car_upload_id) {
    event.preventDefault();
    let user = this.state.user.user
    let upload_code = this.state.upload_code
    let car_blames = this.state.car_blames
    let input_file_name = event.target.name

    const data = new FormData()
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('upload_code', upload_code)
    data.append('user_created', user)

    // update src by no
   let car_blame_new = car_blames.map(car_blame => {
     if(car_blame.no === no) {
       return {...car_blame, src: 'img/loading.gif'}
     }
     return  car_blame
   })

    this.setState({car_blames: car_blame_new}, async () => {

      data.append('car_upload_position', input_file_name)
      data.append('car_upload_id', car_upload_id)

      await axios.post(APIURL + '/car_upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          let dataResult = res.data
          let car_upload_id = 0

          if (dataResult.car_upload_id !== undefined) {
            car_upload_id = dataResult.car_upload_id
          }

          let image_url = '';

          if(dataResult.image.includes('https')) {
            image_url = dataResult.image;
          } else {
            image_url = IMAGE_FULL_URL+dataResult.image;
          }

          if(dataResult.image === undefined) {
            image_url = IMAGE_UPLOAD_ERROR
          } else if (dataResult.image === 'uploaderror') {
            image_url = IMAGE_UPLOAD_ERROR
          }

          if (!DocUtil.isImage(image_url)) {
            image_url = IMAGE_PDF_URL
          }

          let car_blame_new2 =this.state.car_blames.map(car_blame => {
            if(car_blame.no === no) {
              return {...car_blame, src: image_url, car_upload_id: car_upload_id}
            }
            return  car_blame
          })

          this.setState({
            car_blames: car_blame_new2
          })

        }).catch(error => {
          console.log('error:', error)
        })

    })
    // car_blame.src = 'img/loading.gif'
    // this.refs.img_front_car.src = 'img/loading.gif'
    // this.refs.img_front_car.height = 32
  }

  async handleUploadFile (event) {
    event.preventDefault();
    let input_file_name = event.target.name
    let that = this
    let user = this.state.user.user

    // alert(event.target.files[0]);
    // alert(this.state.upload_code);

    let fileSizeBytes = event.target.files[0].size
    let fileSizeMB = fileSizeBytes / (1024 ** 2)
    if (fileSizeMB > 10.0) {
      AlertError('ขนาดภาพเกิน 10 MB ไม่สามารถอัพโหลดได้')
      return
    }

    const data = new FormData()

    data.append('uploads', event.target.files[0], event.target.files[0].name)
    // data.append('file_upload', event.target.files[0]);
    data.append('upload_code', this.state.upload_code)
    data.append('user_created', user)
    // data.append('car_upload_position', input_file_name);
    // alert(this.state.upload_code)

    if (input_file_name === 'front_car') {
      this.refs.img_front_car.src = 'img/loading.gif'
      this.refs.img_front_car.height = 32
      data.append('car_upload_position', 'front_car')
      data.append('car_upload_id', this.state.front_car_id)
    } else if (input_file_name === 'front_right') {
      this.refs.img_front_right.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_right')
      data.append('car_upload_id', this.state.front_right_id )
    } else if (input_file_name === 'front_left') {
      this.refs.img_front_left.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left')
      data.append('car_upload_id', this.state.front_left_id )
    } else if (input_file_name === 'side_car_left') {
      this.refs.img_side_car_left.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_left')
      data.append('car_upload_id', this.state.side_car_left_id )
    } else if (input_file_name === 'side_car_right') {
      this.refs.img_side_car_right.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_right')
      data.append('car_upload_id', this.state.side_car_right_id )
    } else if (input_file_name === 'back_straight') {
      this.refs.img_back_straight.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_straight')
      data.append('car_upload_id', this.state.back_straight_id )
    } else if (input_file_name === 'console_car') {
      this.refs.img_console_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'console_car')
      data.append('car_upload_id', this.state.console_car_id )
    } else if (input_file_name === 'condition_in_car') {
      this.refs.img_condition_in_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'condition_in_car')
      data.append('car_upload_id', this.state.condition_in_car_id )
    } else if (input_file_name === 'engine_car') {
      this.refs.img_engine_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_car')
      data.append('car_upload_id', this.state.engine_car_id )
    } else if (input_file_name === 'plate_car') {
      this.refs.img_plate_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate_car')
      data.append('car_upload_id', this.state.plate_car_id )
    } else if (input_file_name === 'other_car') {
      this.refs.img_other_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car')
      data.append('car_upload_id', this.state.other_car_id )
      // add เพิ่ม
    } else if (input_file_name === 'console') {
      // 12.คอนโซล
      this.refs.img_console.src = 'img/loading.gif'
      data.append('car_upload_position', 'console')
      data.append('car_upload_id', this.state.console_id )
    } else if (input_file_name === 'gear') {
      // 13.เกียร์
      this.refs.img_gear.src = 'img/loading.gif'
      data.append('car_upload_position', 'gear')
      data.append('car_upload_id', this.state.gear_id )
    } else if (input_file_name === 'audio_equipment') {
      // 14.เครื่องเสียง
      this.refs.img_audio_equipment.src = 'img/loading.gif'
      data.append('car_upload_position', 'audio_equipment')
      data.append('car_upload_id', this.state.audio_equipment_id )
    } else if (input_file_name === 'dial') {
      // 15) หน้าปัด
      this.refs.img_dial.src = 'img/loading.gif'
      data.append('car_upload_position', 'dial')
      data.append('car_upload_id', this.state.dial_id )
    } else if (input_file_name === 'catalyticconverter') {
      this.refs.img_catalyticconverter.src = 'img/loading.gif'
      data.append('car_upload_position', 'catalyticconverter')
      data.append('car_upload_id', this.state.catalyticconverter_id )
    } else if (input_file_name === 'yearoftire') {
      this.refs.img_yearoftire.src = 'img/loading.gif'
      data.append('car_upload_position', 'yearoftire')
      data.append('car_upload_id', this.state.yearoftire_id )
    } else if (input_file_name === 'chassis') {
      this.refs.img_chassis.src = 'img/loading.gif'
      data.append('car_upload_position', 'chassis')
      data.append('car_upload_id', this.state.chassis_id )
    }else if (input_file_name === 'other1') {
      this.refs.img_other1.src = 'img/loading.gif'
      data.append('car_upload_position', 'other1')
      data.append('car_upload_id', this.state.other1_id )
    }else if (input_file_name === 'other2') {
      this.refs.img_other2.src = 'img/loading.gif'
      data.append('car_upload_position', 'other2')
      data.append('car_upload_id', this.state.other2_id )
    }else if (input_file_name === 'other3') {
      this.refs.img_other3.src = 'img/loading.gif'
      data.append('car_upload_position', 'other3')
      data.append('car_upload_id', this.state.other3_id )
    }else if (input_file_name === 'plate') {
      // แผ่นเพลท
      this.refs.img_plate.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate')
      data.append('car_upload_id', this.state.plate_id )
    }else if (input_file_name === 'engine_oil') {
      // คอน้ำมันเครื่อง
      this.refs.img_engine_oil.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_oil')
      data.append('car_upload_id', this.state.engine_oil_id )
    }else if (input_file_name === 'front_left_tire_number') {
      this.refs.img_front_left_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left_tire_number')
      data.append('car_upload_id', this.state.front_left_tire_number_id )
    }else if (input_file_name === 'front_right_tire_number') {
      this.refs.img_front_right_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_right_tire_number')
      data.append('car_upload_id', this.state.front_right_tire_number_id )
    }else if (input_file_name === 'back_left_tire_number') {
      this.refs.img_back_left_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_left_tire_number')
      data.append('car_upload_id', this.state.back_left_tire_number_id )
    }else if (input_file_name === 'back_right_tire_number') {
      this.refs.img_back_right_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_right_tire_number')
      data.append('car_upload_id', this.state.back_right_tire_number_id )
    }else if (input_file_name === 'car_key') {
      // car_key
      this.refs.img_car_key.src = 'img/loading.gif'
      data.append('car_upload_position', 'car_key')
      data.append('car_upload_id', this.state.car_key_id )
    }else if (input_file_name === 'cover_car') {
      // cover_car
      this.refs.img_cover_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'cover_car')
      data.append('car_upload_id', this.state.cover_car_id )
    }

    // headers: { "Content-Type": "multipart/form-data" },

    // const url = APIURL + '/car_upload'
    // let options = {
    //   method: "POST",
    //   headers: { "content-type": "application/x-www-form-urlencoded" },
    //   data: data,
    //   url
    // };

    // alert(input_file_name)
    await axios.post(APIURL + '/car_upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        let data = res.data
        let car_upload_id = 0

        if (data.car_upload_id !== undefined) {
          car_upload_id = data.car_upload_id
        }

        let image_url = '';

        if(data.image.includes('https')) {
          image_url = data.image;
        } else {
          image_url = IMAGE_FULL_URL+data.image;
        }

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }

        let img = new Image()
        img.src = image_url
        img.addEventListener('load', function () {

          if (input_file_name === 'front_car') {
            that.refs.img_front_car.src = image_url
            that.setState({ front_car_id: car_upload_id })
          } else if (input_file_name === 'front_right') {
            that.refs.img_front_right.src = image_url
            that.setState({ front_right_id: car_upload_id })
          } else if (input_file_name === 'front_left') {
            that.refs.img_front_left.src = image_url
            that.setState({ front_left_id: car_upload_id })
          } else if (input_file_name === 'side_car_left') {
            that.refs.img_side_car_left.src = image_url
            that.setState({ side_car_left_id: car_upload_id })
          } else if (input_file_name === 'side_car_right') {
            that.refs.img_side_car_right.src = image_url
            that.setState({ side_car_right_id: car_upload_id })
          } else if (input_file_name === 'back_straight') {
            that.refs.img_back_straight.src = image_url
            that.setState({ back_straight_id: car_upload_id })
          } else if (input_file_name === 'console_car') {
            that.refs.img_console_car.src = image_url
            that.setState({ console_car_id: car_upload_id })
          } else if (input_file_name === 'condition_in_car') {
            that.refs.img_condition_in_car.src = image_url
            that.setState({ condition_in_car_id: car_upload_id })
          } else if (input_file_name === 'engine_car') {
            that.refs.img_engine_car.src = image_url
            that.setState({ engine_car_id: car_upload_id })
          } else if (input_file_name === 'plate_car') {
            that.refs.img_plate_car.src = image_url
            that.setState({ plate_car_id: car_upload_id })
          } else if (input_file_name === 'other_car') {
            that.refs.img_other_car.src = image_url
            that.setState({ other_car_id: car_upload_id })
            // add เพิ่ม
          } else if (input_file_name === 'console') {
            // 12
            that.refs.img_console.src = image_url
            that.setState({ console_id: car_upload_id })
          } else if (input_file_name === 'gear') {
            // 13
            that.refs.img_gear.src = image_url
            that.setState({ gear_id: car_upload_id })
          } else if (input_file_name === 'audio_equipment') {
            // 14
            that.refs.img_audio_equipment.src = image_url
            that.setState({ audio_equipment_id: car_upload_id })
          } else if (input_file_name === 'dial') {
            // 15
            that.refs.img_dial.src = image_url
            that.setState({ dial_id: car_upload_id })
          } else if (input_file_name === 'catalyticconverter') {
            that.refs.img_catalyticconverter.src = image_url
            that.setState({ catalyticconverter_id: car_upload_id })
          } else if (input_file_name === 'yearoftire') {
            that.refs.img_yearoftire.src = image_url
            that.setState({ yearoftire_id: car_upload_id })
          } else if (input_file_name === 'chassis') {
            // 18
            that.refs.img_chassis.src = image_url
            that.setState({ chassis_id: car_upload_id })
          } else if (input_file_name === 'other1') {
            // 19
            that.refs.img_other1.src = image_url
            that.setState({ other1_id: car_upload_id })
          }else if (input_file_name === 'other2') {
            // 20
            that.refs.img_other2.src = image_url
            that.setState({ other2_id: car_upload_id })
          }else if (input_file_name === 'other3') {
            // 21
            that.refs.img_other3.src = image_url
            that.setState({ other3_id: car_upload_id })
          }else if (input_file_name === 'plate') {
            that.refs.img_plate.src = image_url
            that.setState({ plate_id: car_upload_id })
          }else if (input_file_name === 'engine_oil') {
            that.refs.img_engine_oil.src = image_url
            that.setState({ engine_oil_id: car_upload_id })
          }else if (input_file_name === 'front_left_tire_number') {
            that.refs.img_front_left_tire_number.src = image_url
            that.setState({ front_left_tire_number_id: car_upload_id })
          }else if (input_file_name === 'front_right_tire_number') {
            that.refs.img_front_right_tire_number.src = image_url
            that.setState({ front_right_tire_number_id: car_upload_id })
          }else if (input_file_name === 'back_left_tire_number') {
            that.refs.img_back_left_tire_number.src = image_url
            that.setState({ back_left_tire_number_id: car_upload_id })
          }else if (input_file_name === 'back_right_tire_number') {
            that.refs.img_back_right_tire_number.src = image_url
            that.setState({ back_right_tire_number_id: car_upload_id })
          }else if (input_file_name === 'car_key') {
            that.refs.img_car_key.src = image_url
            that.setState({ car_key_id: car_upload_id })
          }else if (input_file_name === 'cover_car') {
            that.refs.img_cover_car.src = image_url
            that.setState({ cover_car_id: car_upload_id })
          }
        });

      })
      .catch(error => {
        console.log(error.response);
        // alert('error:' + error.response)
        if(error.response.status === 503) {
          let img = new Image()
          let image_url = IMAGE_UPLOAD_ERROR
          img.src = image_url
          img.addEventListener('load', () => {

            if (input_file_name === 'front_car2') {
              this.refs.img_front_car2.src = image_url
            } else if (input_file_name === 'front_right2') {
              this.refs.img_front_right2.src = image_url
            } else if (input_file_name === 'front_left2') {
              this.refs.img_front_left2.src = image_url
            } else if (input_file_name === 'side_car_left2') {
              this.refs.img_side_car_left2.src = image_url
            } else if (input_file_name === 'side_car_right2') {
              this.refs.img_side_car_right2.src = image_url
            } else if (input_file_name === 'back_straight2') {
              this.refs.img_back_straight2.src = image_url
            } else if (input_file_name === 'console_car2') {
              this.refs.img_console_car2.src = image_url
            } else if (input_file_name === 'condition_in_car2') {
              this.refs.img_condition_in_car2.src = image_url
            } else if (input_file_name === 'engine_car2') {
              this.refs.img_engine_car2.src = image_url
            } else if (input_file_name === 'plate_car2') {
              this.refs.img_plate_car2.src = image_url
            } else if (input_file_name === 'other_car2') {
              this.refs.img_other_car2.src = image_url
            }

          })
        }

      })

  }

  handleUploadFile2 (event) {

    var input_file_name = event.target.name
    var that = this
    let user = this.state.user.user
    // alert(event.target.files[0]);
    // alert(this.state.upload_code);

    const data = new FormData()

    data.append('uploads', event.target.files[0], event.target.files[0].name)
    // data.append('file_upload', event.target.files[0]);
    data.append('upload_code', this.state.upload_code)
    data.append('user_created', user)
    // data.append('car_upload_position', input_file_name);

    if (input_file_name === 'front_car2') {
      this.refs.img_front_car2.src = 'img/loading.gif'
      this.refs.img_front_car2.height = 32
      data.append('car_upload_position', 'front_car2')
      data.append('car_upload_id', this.state.front_car2_id)
    } else if (input_file_name === 'front_right2') {
      this.refs.img_front_right2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_right2')
      data.append('car_upload_id', this.state.front_right2_id)
    } else if (input_file_name === 'front_left2') {
      this.refs.img_front_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left2')
      data.append('car_upload_id', this.state.front_left2_id)
    } else if (input_file_name === 'side_car_left2') {
      this.refs.img_side_car_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_left2')
      data.append('car_upload_id', this.state.side_car_left2_id)
    } else if (input_file_name === 'side_car_right2') {
      this.refs.img_side_car_right2.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_right2')
      data.append('car_upload_id', this.state.side_car_right2_id)
    } else if (input_file_name === 'back_straight2') {
      this.refs.img_back_straight2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_straight2')
      data.append('car_upload_id', this.state.back_straight2_id)
    } else if (input_file_name === 'console_car2') {
      this.refs.img_console_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'console_car2')
      data.append('car_upload_id', this.state.console_car2_id)
    } else if (input_file_name === 'condition_in_car2') {
      this.refs.img_condition_in_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'condition_in_car2')
      data.append('car_upload_id', this.state.condition_in_car2_id)
    } else if (input_file_name === 'engine_car2') {
      this.refs.img_engine_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_car2')
      data.append('car_upload_id', this.state.engine_car2_id)
    } else if (input_file_name === 'plate_car2') {
      this.refs.img_plate_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate_car2')
      data.append('car_upload_id', this.state.plate_car2_id)
    } else if (input_file_name === 'other_car2') {
      this.refs.img_other_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car2')
      data.append('car_upload_id', this.state.other_car2_id)
    }

    //alert(input_file_name)

    axios.post(APIURL + '/car_upload', data)
      .then(res => {
        let data = res.data
        let car_upload_id = 0

        if (data.car_upload_id !== undefined) {
          car_upload_id = data.car_upload_id
        }


        // let image_url = IMAGE_FULL_URL + data.image
        //
        // if(data.image === undefined) {
        //   image_url = IMAGE_UPLOAD_ERROR
        // } else if (data.image === 'uploaderror') {
        //   image_url = IMAGE_UPLOAD_ERROR
        // }

        var image_url = '';


        if(data.image.includes('https')) {
          image_url = data.image;
        } else {
          image_url = IMAGE_FULL_URL+data.image;
        }

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }


        let img = new Image()
        img.src = image_url
        img.addEventListener('load', () => {

          if (input_file_name === 'front_car2') {
            this.refs.img_front_car2.src = image_url
            that.setState({ front_car2_id: car_upload_id })
          } else if (input_file_name === 'front_right2') {
            this.refs.img_front_right2.src = image_url
            that.setState({ front_right2_id: car_upload_id })
          } else if (input_file_name === 'front_left2') {
            this.refs.img_front_left2.src = image_url
            that.setState({ front_left2_id: car_upload_id })
          } else if (input_file_name === 'side_car_left2') {
            this.refs.img_side_car_left2.src = image_url
            that.setState({ side_car_left2_id: car_upload_id })
          } else if (input_file_name === 'side_car_right2') {
            this.refs.img_side_car_right2.src = image_url
            that.setState({ side_car_right2_id: car_upload_id })
          } else if (input_file_name === 'back_straight2') {
            this.refs.img_back_straight2.src = image_url
            that.setState({ back_straight2_id: car_upload_id })
          } else if (input_file_name === 'console_car2') {
            this.refs.img_console_car2.src = image_url
            that.setState({ console_car2_id: car_upload_id })
          } else if (input_file_name === 'condition_in_car2') {
            this.refs.img_condition_in_car2.src = image_url
            that.setState({ condition_in_car2_id: car_upload_id })
          } else if (input_file_name === 'engine_car2') {
            this.refs.img_engine_car2.src = image_url
            that.setState({ engine_car2_id: car_upload_id })
          } else if (input_file_name === 'plate_car2') {
            this.refs.img_plate_car2.src = image_url
            that.setState({ plate_car2_id: car_upload_id })
          } else if (input_file_name === 'other_car2') {
            this.refs.img_other_car2.src = image_url
            that.setState({ other_car2_id: car_upload_id })
          }
        })
      })
      .catch(function (error) {
        // console.log(error.response);

        if(error.response.status === 503) {
          let img = new Image()
          let image_url = IMAGE_UPLOAD_ERROR
          img.src = image_url
          img.addEventListener('load', () => {

            if (input_file_name === 'front_car2') {
              this.refs.img_front_car2.src = image_url
            } else if (input_file_name === 'front_right2') {
              this.refs.img_front_right2.src = image_url
            } else if (input_file_name === 'front_left2') {
              this.refs.img_front_left2.src = image_url
            } else if (input_file_name === 'side_car_left2') {
              this.refs.img_side_car_left2.src = image_url
            } else if (input_file_name === 'side_car_right2') {
              this.refs.img_side_car_right2.src = image_url
            } else if (input_file_name === 'back_straight2') {
              this.refs.img_back_straight2.src = image_url
            } else if (input_file_name === 'console_car2') {
              this.refs.img_console_car2.src = image_url
            } else if (input_file_name === 'condition_in_car2') {
              this.refs.img_condition_in_car2.src = image_url
            } else if (input_file_name === 'engine_car2') {
              this.refs.img_engine_car2.src = image_url
            } else if (input_file_name === 'plate_car2') {
              this.refs.img_plate_car2.src = image_url
            } else if (input_file_name === 'other_car2') {
              this.refs.img_other_car2.src = image_url
            }

          })
        }

      })

  }

  handleOnChange () {

    let sale_price = this.state.car_sale_price;
    let car_cost = this.state.car_cost;
    let car_cost_repair = this.state.car_cost_repair;
    let car_cost_tax = this.state.car_cost_tax;
    let car_cost_act = this.state.car_cost_act;
    let car_cost_commissions = this.state.car_cost_commissions;
    let car_cost_expenses_other = this.state.car_cost_expenses_other;
    let car_cost_recipe_other = this.state.car_cost_recipe_other;
    //
    let cost_operation = this.state.cost_operation === '' ? 0 : this.state.cost_operation;
    let cost_service = this.state.cost_service === '' ? 0 : this.state.cost_service;
    let cost_transport = this.state.cost_transport === '' ? 0 : this.state.cost_transport;
    let cost_porobor_lan = this.state.cost_porobor_lan === '' ? 0 : this.state.cost_porobor_lan;
    let cost_bangkhab_phasi_lan = this.state.cost_bangkhab_phasi_lan === '' ? 0 : this.state.cost_bangkhab_phasi_lan;
    let cost_bangkhab_xon_lan = this.state.cost_bangkhab_xon_lan === '' ? 0 : this.state.cost_bangkhab_xon_lan;
    let cost_check_ton = this.state.cost_check_ton === '' ? 0 : this.state.cost_check_ton;
    let vat_buy = this.state.vat_buy === '' ? 0 : this.state.vat_buy;

    if (car_cost === ''){
      car_cost = 0;
    }

    if (car_cost_repair === ''){
      car_cost_repair = 0;
    }

    if (car_cost_tax === '') {
      car_cost_tax = 0;
    }

    if (car_cost_act === ''){
      car_cost_act = 0;
    }

    if (car_cost_commissions === ''){
      car_cost_commissions = 0;
    }

    if (car_cost_expenses_other === ''){
      car_cost_expenses_other = 0;
    }

    if (car_cost_recipe_other === ''){
      car_cost_recipe_other = 0;
    }

    let profite = 0;
    if (sale_price !== '' && sale_price > 0) {
      profite = Number(sale_price) - Number(car_cost) - Number(car_cost_repair) - Number(car_cost_tax) - Number(car_cost_act) - Number(car_cost_commissions) - Number(car_cost_expenses_other) + Number(car_cost_recipe_other)
      profite -= Number(cost_operation);
      profite -= Number(cost_service);
      profite -= Number(cost_transport);
      profite -= Number(cost_porobor_lan);
      profite -= Number(cost_bangkhab_phasi_lan);
      profite -= Number(cost_bangkhab_xon_lan);
      profite -= Number(cost_check_ton);
      profite -= Number(vat_buy);
    }

    this.setState({
      car_net_profit: profite
    })
  }

  handleOnChangeNoSet (event) {

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  handleCarDateIn(date) {
    this.setState({
      car_date_in: date
    })
  }

  //floorplan_due_date
  handleFloorplanDueDate(date) {
    this.setState({
      floorplan_due_date: date
    })
  }

  //floorplan_start_date
  handleFloorplanStartDate(date) {
    this.setState({
      floorplan_start_date: date
    })
  }

  handleReceiptDate(date) {
    this.setState({
      receipt_date: date
    })
  }

  handleInsuranceEndDate(date) {
    this.setState({
      insurance_end_date: date
    })
  }


  loadCarParking() {
    let url = APIURL+'/car_parking'
    axios.get(url).then(res => {
      let data = res.data
      this.setState({
        car_parking_list: data
      })
    })
  }

  checkCarLicenseOld() {
    let car_license_plate_old = this.state.car_license_plate_old
    if(car_license_plate_old === ''){
      return
    }

    let data = {
      license_old: car_license_plate_old
    }
    let url = APIURL+'/car/check_license_old'
    axios.post(url, data).then(res => {

      if(res.status === 200 && res.data.length === 0){
        this.setState({
          repeat_license_old: 'pass'
        })
      } else if (res.status === 200 && res.data.length > 0) {
        let len = res.data.length
        let car = res.data[0]
        let repeat_license_old_text = `ซ้ำ(${len}) | ${car.car_license_plate_old} ${car.province_old_name} ${car.car_status_name}`
        this.setState({
          repeat_license_old: 'repeat',
          repeat_license_old_text: repeat_license_old_text
        })
      }
    })
  }

  // border: 1px solid rgba(0, 0, 0, 0.15);
  // 'rgba(0, 0, 0, 0.15)'
  checkCarLicenseOldClass(repeat_license_old) {
    // let repeat_license_old = this.state.repeat_license_old
   if (repeat_license_old === 'repeat'){
      return 'rgba(255, 99, 71, 0.8)'
    } else if (repeat_license_old === 'pass'){
      return 'rgb(60, 179, 113)'
    }
    return 'rgba(0, 0, 0, 0.15)'
  }

  render () {

    // const {history, user} = this.props

    let car_brand_list = this.state.car_brands.map((car_brand, index) => {
      return (
        <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
      )
    })
    let car_brand_model = this.state.car_models.map((car_model, index) => {
      return (
        <option key={index} value={car_model.car_model_id}>{car_model.car_model_name}</option>
      )
    })
    let car_sub_model = this.state.car_sub_models.map((car_sub_model, index) => {
      return (
        <option key={index} value={car_sub_model.car_sub_model_id}>{car_sub_model.car_sub_model_name}</option>
      )
    })
    let car_year_list = this.state.car_years.map((car_year, index) => {
      return (
        <option key={index} value={car_year.car_year_id}>{car_year.car_year_name}</option>
      )
    })
    let car_color_list = this.state.car_colors.map((car_color, index) => {
      return (
        <option key={index} value={car_color.car_color_id}>{car_color.car_color_name}</option>
      )
    })
    let car_gear_list = this.state.car_gears.map((car_gear, index) => {
      return (
        <option key={index} value={car_gear.car_gear_id}>{car_gear.car_gear_name}</option>
      )
    })

    let car_status_list = this.state.car_status.map((car_status, index) => {
      return (
        <option key={index} value={car_status.car_status_id}>{car_status.car_status_name}</option>
      )
    })

    let province_old_list = this.state.province.map((province, index) => {
      return (
        <option key={index} value={province.province_id}>{province.province_name}</option>
      )
    })

    var province_new_list = this.state.province.map((province, index) => {
      return (
        <option key={index} value={province.province_id}>{province.province_name}</option>
      )
    })

    var car_branch_list = this.state.branch.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    var car_hassock_list = this.state.car_hassock.map((car_hassock, index) => {
      return (
        <option key={index}
                value={car_hassock.car_hassock_id}>{car_hassock.car_hassock_name}</option>
      )
    })

    var car_system_drive_list = this.state.car_system_drive.map((car_system_drive, index) => {
      return (
        <option key={index}
                value={car_system_drive.car_system_drive_id}>{car_system_drive.car_system_drive_name}</option>
      )
    })

    var car_type_engine_list = this.state.car_type_engine.map((car_type_engine, index) => {
      return (
        <option key={index} value={car_type_engine.car_type_engine_id}>{car_type_engine.car_type_engine_name}</option>
      )
    })

    var car_type_list = this.state.car_type.map((car_type, index) => {
      return (
        <option key={index} value={car_type.car_type_id}>{car_type.car_type_name}</option>
      )
    })

    var car_structure_list = this.state.car_structure.map((car_structure, index) => {
      return (
        <option key={index} value={car_structure.car_structure_id}>{car_structure.car_structure_name}</option>
      )
    })

    var car_finance_list = this.state.banks.map((bank, index) => {
      if (bank.bank_id > 2) {
        return (
          <option key={index} value={bank.bank_id}>{index-1}.{bank.bank_name}</option>
        )
      } else {
        return null
      }
    });

    let car_floorplan_finance_list = this.state.banks.map((bank, index) => {
      if(PORT === 9005) {
        if (bank.bank_id > 2 && (bank.bank_name.includes('ทิสโก้') || bank.bank_name.includes('กรุงศรี'))) {
          return (
            <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
          )
        } else {
          return null
        }
      }else{
        if (bank.bank_id > 2 ) {
          return (
            <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
          )
        } else {
          return null
        }
      }
    });


    let insurance_types = this.state.insurance_types.map((insurance_type, index) => {
      return (
        <option key={index} value={insurance_type.insurance_type_id}>{insurance_type.insurance_type_name}</option>
      )
    })

    let insurance_companies = this.state.insurance_companies.map((insurance_company, index) => {
      return (
        <option key={index} value={insurance_company.insurance_company_id}>{insurance_company.insurance_company_name}</option>
      )
    })

    let compulsory_insurance_company = this.state.insurance_companies.map((insurance_company, index) => {
      return (
        <option key={index} value={insurance_company.insurance_company_id}>{insurance_company.insurance_company_name}</option>
      )
    })

    let carParkingOptions = this.state.car_parking_list.map((parking, index) => {
      return <option value={parking.car_parking_name} key={index}> {parking.car_parking_name} </option>
    })

    return (
      <div className="card">

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <form action="" method="post" onSubmit={this.handleOnSubmitCar}>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header card-header-info">
                    <strong>ข้อมูลเจ้าของเดิม (ล่าสุด)</strong>
                  </div>
                  <div className="card-block">
                    <div className="row">

                      <div className="form-group col-sm-3">
                        <label htmlFor="name">ลำดับที่ (ในเล่มทะเบียนรถ)</label>
                        <input type="number"
                               className="form-control"
                               name="car_owner_old_order"
                               maxLength={2}
                               placeholder="ลำดับที่"/>
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="name">ชื่อ</label>
                        <input type="text"
                               className="form-control"
                               name="car_name_owner_old"
                               ref="car_name_owner_old"
                               placeholder="กรอกชื่อ"/>
                      </div>
                      <div className="form-group col-sm-3">
                        <label htmlFor="lastname">นามสกุล</label>
                        <input type="text" className="form-control" name="car_lastname_owner_old"
                               placeholder="กรอกนามสกุล"/>
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="id_card">เลขบัตรประจำตัวประชาชน</label>
                        <input type="text" className="form-control hidden"
                               name="car_id_card_old-bk"
                               placeholder="เลขบัตรประจำตัวประชาชน" maxLength="20"/>

                        <MaskedInput
                            mask="1-1111-11111-11-1"
                            name="car_id_card"
                            placeholder="เลขบัตรประจำตัวประชาชน"
                            className="form-control"
                        />
                      </div>

                      <div className="form-group col-sm-6">
                        <label htmlFor="license-plate-old">
                           ป้ายทะเบียนเดิม *
                           &nbsp;{this.state.repeat_license_old_text}
                        </label>
                        <input type="text"
                               className="form-control"
                               style={{borderColor: this.checkCarLicenseOldClass(this.state.repeat_license_old)}}
                               name="car_license_plate_old"
                               ref="car_license_plate_old"
                               placeholder="ป้ายทะเบียนเดิม"
                               value={this.state.car_license_plate_old}
                               onChange={(e) => {
                                 this.setState({
                                    repeat_license_old: 'noset',
                                    repeat_license_old_text: '',
                                    car_license_plate_old: e.target.value
                                 })
                               }}
                               onBlur={(e) => {
                                 this.checkCarLicenseOld();
                               }}
                        />
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="province">จังหวัด</label>
                        <select className="form-control" id="province_old_id">
                          {province_old_list}
                        </select>
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="telephone-old">เบอร์โทรศัพท์</label>
                        <input type="text" className="form-control hidden"
                               name="car_telephone_old-bk"
                               placeholder=""/>

                        <MaskedInput
                            mask="111-111-1111"
                            name="car_telephone_old"
                            placeholder="เบอร์โทรศัพท์"
                            // onChange={this.onMaskedDateAgent}
                            className="form-control"
                            // value={this.state.agent_birth_text}
                        />
                      </div>

                      <div className="form-group col-sm-6">
                        <label htmlFor="address-old">ที่อยู่ตามทะเบียนบ้าน</label>
                        <input type="text" className="form-control" name="car_address_old"
                               placeholder="กรอกข้อมูลที่อยู่ตามทะเบียนบ้าน"/>
                      </div>

                      <div className="form-group col-sm-6">
                        <label htmlFor="other">หมายเหตุ</label>
                        <input type="text" className="form-control" name="car_other"
                               placeholder="ข้อมูลเพิ่มเติม"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header card-header-warning">
                    <strong>เพิ่มข้อมูลรถใหม่</strong>
                  </div>

                  <div className="card-block">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row" style={styles.bottomLine}>

                          <div className="form-group col-sm-5">
                            <label htmlFor="branch">สาขาที่รถอยู่</label>
                            <select className="form-control" id="branch_id" ref="branch_id">
                              {car_branch_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-3">
                            {/*<label htmlFor="car_date_in">วันที่ซื้อรถเข้ามา</label>
                            <MaskedInput
                              mask="11-11-1111"
                              name="car_date_in_text"
                              placeholder="dd-mm-yyyy"
                              onChange={this.onMaskedChange1}
                              className="form-control"
                            />*/}
                            {/*<br/>
                            <DatePicker
                             type="text"
                             className="form-control col-sm-12"
                             name="car_date_in"
                             placeholder=""
                             selected={this.state.car_date_in}
                             onChange={this.handleCarDateIn}
                             dateFormat="dd-MM-yyyy"
                             />*/}

                            <BDatePicker
                              isDateFill={this.state.is_date_fill}
                              name={'car_date_in'}
                              value={this.state.car_date_in}
                              label={'วันที่ซื้อรถเข้ามา '}
                              onUpdateDate={(buddhist_date, christ_date) => {
                                this.setState({
                                  car_date_in: christ_date
                                })
                              }}
                            />

                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_date_in">ซื้อรถจาก</label>
                            <input type="text"
                                   className="form-control"
                                   id="buy_car_from"
                                   name="buy_car_from"
                                   ref="buy_car_from"
                                   value={this.state.buy_car_from}
                                   placeholder="ซื้อรถจาก"
                                   onChange={(e) => {
                                     this.setState({
                                       buy_car_from: e.target.value
                                     })
                                   }}
                            />
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_name">ตำแหน่งรถจอด</label>
                            <select className="form-control"
                                    id="park_location"
                                    ref="park_location"
                                    onChange={(e) => {
                                       this.setState({
                                         park_location: e.target.value
                                       })
                                    }}
                                    value={this.state.park_location}
                            >
                              {carParkingOptions}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_grade">เกรดรถ</label>
                            <select className="form-control"
                                    id="car_grade"
                                    name="car_grade"
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <BDatePicker
                              isDateFill={this.state.is_date_fill}
                              name={'warantee_date_end'}
                              value={this.state.warantee_date_end}
                              label={'วันที่ warantee สิ้นสุด '}
                              onUpdateDate={(buddhist_date, christ_date) => {
                                this.setState({
                                  warantee_date_end: christ_date
                                })
                              }}
                            />
                          </div>

                          {/*{port === 9038 || port === 9039 ?
                            <div className="form-group col-sm-3">
                              <label htmlFor="code_item">รหัสสินค้า / รหัสรถ</label>
                              <input type="text"
                                     className="form-control"
                                     name="code_item"
                                     placeholder="กรอกรหัสสินค้า"
                              />
                            </div>
                          : null
                          }*/}

                          <div className="form-group col-sm-3">
                            <label htmlFor="code_item">รหัสสินค้า / รหัสรถ</label>
                            <input type="text"
                                   className="form-control"
                                   name="code_item"
                                   placeholder="กรอกรหัสสินค้า"
                            />
                          </div>


                          <div className="form-group col-sm-9">
                            <label htmlFor="car_name">ชื่อรถ</label>
                            <input type="text"
                                   className="form-control"
                                   id="car_name"
                                   name="car_name"
                                   ref="car_name"
                                   value={this.state.car_name}
                                   placeholder="ชื่อรถ-สร้างอัตโนมัติ เลือกยี่ห้อ รุ่น สี "
                                   onChange={this.handleCarNameChange}
                            />
                          </div>

                          <div className="form-group col-sm-12">
                            <label htmlFor="detail">รายละเอียดรถ</label>
                            <input
                              type="text" className="form-control"
                              id="car_detail"
                              name="car_detail"
                              ref="car_detail"
                              value={this.state.car_detail}
                              placeholder="รายละเอียดรถ"
                              onChange={this.handleCarDetailChange}
                            />
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_brand_id">ยี่ห้อ</label>
                            <select className="form-control"
                                    id="car_brand_id"
                                    name="car_brand_id"
                                    ref="car_brand_id"
                                    onChange={this.handleCarBrandChange.bind(this)}
                            >
                              <option value="">เลือกยี่ห้อรถ</option>
                              {car_brand_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_model_id">รุ่นรถ</label>
                            <select className="form-control"
                                    id="car_model_id"
                                    name="car_model_id"
                                    onChange={this.handleCarModelChange.bind(this)}
                            >
                              <option value="">เลือกรุ่นรถ</option>
                              <option value="0">ไม่ระบุ</option>
                              {car_brand_model}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_sub_model_id">รุ่นย่อย</label>
                            <select className="form-control"
                                    id="car_sub_model_id"
                                    name="car_sub_model_id"
                                    ref="car_sub_model"
                                    onChange={this.handleCarSubModelChange}
                            >
                              <option value="">เลือกรุ่นย่อย</option>
                              <option value="0">ไม่ระบุ</option>
                              {car_sub_model}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="gear">ระบบเกียร์รถ</label>
                            <select
                              className="form-control"
                              id="car_gear_id"
                              name="car_gear_id"
                              onChange={this.handleCarGearChange}
                            >
                              {car_gear_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="color">สี</label>
                            <select className="form-control"
                                    id="car_color_id"
                                    name="car_color_id"
                                    onChange={this.handleCarColorChange}
                            >
                              {car_color_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="year">รถปี ค.ศ.</label>
                            <select className="form-control"
                                    id="car_year_id"
                                    name="car_year_id"
                                    onChange={this.handleCarYearChange.bind(this)}
                            >
                              {car_year_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="license-plate-new">ป้ายทะเบียนใหม่ *</label>
                            <input type="text"
                                   className="form-control"
                                   name="car_license_plate_new"
                                   placeholder="กข-xxx"/>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="province">จังหวัด</label>
                            <select className="form-control" id="province_new_id">
                              {province_new_list}
                            </select>
                          </div>


                          <div className="form-group col-sm-4">
                            <label htmlFor="car_number">เลขตัวรถ</label>
                            <input style={styles.toUpper}
                                   type="text"
                                   className="form-control"
                                   name="car_number"
                                   ref="car_number"
                                   placeholder="000-001001"/>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_num_engines">เลขเครื่องยนต์</label>
                            <input style={styles.toUpper}
                                   type="text"
                                   className="form-control"
                                   name="car_num_engines"
                                   ref="car_num_engines"
                                   placeholder="AB00A-xxxx"
                            />
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="car_date_regis_text">วันที่จดทะเบียน (วัน-เดือน-ปีพ.ศ.)</label>

                            <MaskedInput
                              mask="11-11-1111"
                              id="car_date_regis_text"
                              name="car_date_regis_text"
                              placeholder="dd-mm-yyyy"
                              onChange={this.onMaskedChange3}
                              className="form-control"
                            />
                            {/*<DatePicker
                             type="text"
                             className="form-control hidden"
                             name="car_date_regis"
                             placeholder=""
                             selected={this.state.startDateCarNew3}
                             onChange={this.handleDateCarNew3}
                             dateFormat="yyyy-MM-dd"

                             />*/}
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="type">ประเภทรถ (แสดงในใบ สคบ.)</label>
                            <select className="form-control" id="car_type_id">
                              {car_type_list}
                            </select>
                          </div>

                          <div

                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_structure_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="structure">ลักษณะรถ</label>
                            <select className="form-control" id="car_structure_id">
                              {car_structure_list}
                            </select>
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_hassock_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="hassock">เบาะรถ</label>
                            <select className="form-control" id="car_hassock_id">
                              {car_hassock_list}
                            </select>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                              (this.state.form_controls.filter(form_control => form_control.field_name === 'car_system_drive_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                              :  "hidden"
                            }
                          >
                            <label htmlFor="system-drive">ระบบขับเคลื่อน</label>
                            <select
                              className="form-control"
                              id="car_system_drive_id"
                              name="car_system_drive_id"
                            >
                              {car_system_drive_list}
                            </select>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_engine_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="type-engine">ประเภทเครื่องยนต์</label>
                            <select className="form-control"
                                    id="car_type_engine_id"
                                    name="car_type_engine_id"
                            >
                              {car_type_engine_list}
                            </select>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_brand_engine' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="brand-engine">ยี่ห้อเครื่องยนต์</label>
                            <input type="text" className="form-control"
                                   id="car_brand_engine"
                                   name="car_brand_engine"
                                   placeholder="ยี่ห้อเครื่องยนต์"/>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_gas_number' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="gas-number">เลขถังแก๊ส</label>
                            <input type="text" className="form-control"
                                   id="car_gas_number"
                                   name="car_gas_number"
                                   placeholder="เลขถังแก๊ส"/>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_weight' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="weight">น้ำหนักรถ (กิโลกรัม)</label>
                            <input type="text" className="form-control"
                                   id="car_weight"
                                   name="car_weight"
                                   placeholder="น้ำหนักรถ (กิโลกรัม)"/>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_size_engine' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="size-engine">ขนาดเครื่องยนต์ (ซีซี)/ แรงม้า</label>
                            <input type="text" className="form-control"
                                   id="car_size_engine"
                                   name="car_size_engine"
                                   placeholder="ขนาดเครื่องยนต์ (ซีซี) / แรงม้า"/>
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_date_tax_text' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="car_date_tax_text">วันครบกำหนดเสียภาษี (วัน-เดือน-ปีพ.ศ.)</label>
                            <MaskedInput
                              mask="11-11-1111"
                              id="car_date_tax_text"
                              name="car_date_tax_text"
                              placeholder="dd-mm-yyyy"
                              onChange={this.onMaskedChange2}
                              className="form-control"
                            />
                            {/*<DatePicker
                             selected={this.state.startDateCarNew2}
                             onChange={this.handleDateCarNew2}
                             dateFormat="yyyy-MM-dd"
                             type="text"
                             className="form-control"
                             name="car_date_tax"
                             placeholder=""
                             />*/}
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_tax' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                    :  "hidden"
                              }
                          >

                            <label htmlFor="tax-price">ราคาภาษีรถยนต์ / ปี</label>
                            <input type="text" className="form-control" name="car_tax"
                                   placeholder="ราคาภาษีรถยนต์ / ปี"/>
                          </div>

                          <div className="form-group col-sm-4">
                            <label htmlFor="status">สถานะรถ</label>
                            <select className="form-control" id="car_status_id"
                                    value={this.state.car_status_id}
                                    onChange={(e)=>{
                                      let val = e.target.value
                                      console.log('val', val)
                                      this.setState({
                                        car_status_id : val
                                      })
                                    }}
                            >
                              {car_status_list}
                            </select>
                          </div>

                        </div>

                        <div className="row" style={styles.bottomLine}>
                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_miles_center' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="car_miles_center">ไมล์ศูนย์ </label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="car_miles_center"
                                   name="car_miles_center"
                                   placeholder="ไมล์ศูนย์ "
                                   value={this.state.car_miles_center}
                                   onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              placeholder="ไมล์ศูนย์"
                              min={0}
                              max={1000000000}
                              step={1}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({car_miles_center: value})

                              }}
                            />
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_miles_center_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                :  "hidden"
                            }
                          >
                              <BDatePicker
                                isDateFill={this.state.is_date_fill}
                                name={'car_miles_center_date'}
                                value={this.state.car_miles_center_date}
                                label={'วันเช็คไมล์ศูนย์ '}
                                onUpdateDate={(buddhist_date, christ_date) => {
                                  this.setState({
                                    car_miles_center_date: christ_date
                                  })
                                }}
                              />
                          </div>

                          <div
                            className="form-group col-sm-3"
                          >
                            <label htmlFor="miles">เลขไมล์ปัจจุบัน (ไมล์หน้ารถ) </label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="car_miles"
                                   name="car_miles"
                                   placeholder="เลขไมล์ปัจจุบัน (ไมล์หน้ารถ)"
                                   value={this.state.car_miles}
                                   onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              placeholder="เลขไมล์ปัจจุบัน (ไมล์หน้ารถ)"
                              min={0}
                              max={1000000000}
                              step={1}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({car_miles: value})

                              }}
                            />
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_miles_status' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="car_miles_status">สถานะไมล์</label>
                            <select className="form-control"
                                    id="car_miles_status"
                                    name="car_miles_status"
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              <option value="ไมล์แท้">ไมล์แท้</option>
                              <option value="ไมล์แก้ไข">ไมล์แก้ไข</option>
                            </select>
                          </div>

                        </div>

                        <div className="row" style={styles.bottomLine}>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'compulsory_insurance' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="compulsory_insurance">พรบ.</label>
                            <select className="form-control"
                                    id="compulsory_insurance"
                                    name="compulsory_insurance"
                                    onChange={(event) => {
                                      var value  = event.target.value
                                      //alert(value)
                                      if(value === 'ไม่มี') {
                                        this.refs.compulsory_insurance_company.value = 99
                                      }
                                    }}
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              <option value="มี">มี</option>
                              <option value="ไม่มี">ไม่มี</option>
                            </select>
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'compulsory_insurance_company' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="compulsory_insurance_company">บ.พรบ</label>
                            <select className="form-control"
                                    id="compulsory_insurance_company"
                                    name="compulsory_insurance_company"
                                    ref="compulsory_insurance_company"
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              {compulsory_insurance_company}
                            </select>
                          </div>

                          <div className="form-group col-sm-3">
                            <BDatePicker
                              isDateFill={this.state.is_date_fill}
                              name={'compulsory_insurance_end_date'}
                              value={this.state.compulsory_insurance_end_date}
                              label={'วันที่สิ้นสุด พรบ. '}
                              onUpdateDate={(buddhist_date, christ_date) => {
                                this.setState({
                                  compulsory_insurance_end_date: christ_date
                                })
                              }}
                            />

                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_spare_key' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="car_spare_key">กุญแจสำรอง</label>
                            <select className="form-control"
                                    id="car_spare_key"
                                    name="car_spare_key"
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              <option value="มี">มี</option>
                              <option value="ไม่มี">ไม่มี</option>
                            </select>
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'car_manual' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="car_manual">คู่มือรถ</label>
                            <select className="form-control"
                                    id="car_manual"
                                    name="car_manual"
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              <option value="มี">มี</option>
                              <option value="ไม่มี">ไม่มี</option>
                            </select>
                          </div>

                          <div
                            className={
                              this.state.form_controls.length > 0 ?
                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_book_service' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                :  "hidden"
                            }
                          >
                            <label htmlFor="car_book_service">คู่มือบริการ (Book Service)</label>
                            <select className="form-control"
                                    id="car_book_service"
                                    name="car_book_service"
                            >
                              <option value="">-- กรุณาเลือก --</option>
                              <option value="มี">มี</option>
                              <option value="ไม่มี">ไม่มี</option>
                            </select>
                          </div>
                        </div>


                        <div className="row" style={styles.bottomLine}>
                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'insurance_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="insurance_type_id">ประเภทประกันภัย</label>
                            <select
                              className="form-control"
                              id="insurance_type_id"
                              onChange={(event) => {
                                var value  = event.target.value
                                //alert(value)
                                if(value === 6) {
                                  this.refs.insurance_company_id.value = 99
                                }
                              }}
                            >
                              {insurance_types}
                            </select>
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'insurance_company_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="insurance_company_id">บ.ประกันภัย</label>
                            <select className="form-control"
                                    id="insurance_company_id"
                                    ref="insurance_company_id"
                            >
                              {insurance_companies}
                            </select>
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'insurance_end_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="insurance_end_date">วันที่สิ้นสุดประกัน</label>
                            <br/>
                            <DatePicker
                              type="text"
                              className="form-control col-sm-12"
                              name="insurance_end_date"
                              placeholder=""
                              selected={this.state.insurance_end_date}
                              value={this.state.insurance_end_date}
                              onChange={this.handleInsuranceEndDate}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'deposit_registration_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="deposit_registration_id">มัดจำเล่มทะเบียน</label>
                            <select className="form-control"
                                    id="deposit_registration_id">
                              <option value="1">มัดจำเล่ม</option>
                              <option value="2">ไม่มัดจำ</option>
                            </select>
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="liciense_status_id">สถานะเล่ม</label>
                            <select className="form-control"
                                    id="liciense_status_id">
                              <option value="0">--ไม่ระบุ--</option>
                              <option value="1">มีเล่มทะเบียน</option>
                              <option value="2">ยังไม่ปิดบัญชี</option>
                              <option value="3">รอเล่มปิดบัญชี</option>
                              <option value="4">มีเล่มอยู่เต็นท์</option>
                              <option value="5">ไม่มีเล่ม</option>
                            </select>
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="finance_id">ไฟแนนซ์รอเล่มทะเบียน</label>
                            <select className="form-control" id="finance_id">
                              <option value="0"> --- เลือกไฟแนนซ์ --- </option>
                              {car_finance_list}
                            </select>
                          </div>


                          <div className="form-group col-sm-3">
                            <label htmlFor="receipt_date">วันที่รับเล่ม</label>
                            <br/>
                            <DatePicker
                              type="text"
                              className="form-control col-sm-12"
                              name="receipt_date"
                              placeholder=""
                              selected={this.state.receipt_date}
                              value={this.state.receipt_date}
                              onChange={this.handleReceiptDate}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_credit' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="floorplan_credit">ยอดเงินฟลอร์แพลน(จำนวนเงิน)</label>
                            <input type="text"
                                   className="form-control hidden "
                                   name="floorplan_credit"
                                   placeholder=""
                                   ref="floorplan_credit"
                                   value={this.state.floorplan_credit}
                                   onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ยอดเงินฟลอร์แพลน"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({floorplan_credit: value})
                              }}
                            />
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'finance_floorplan_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="finance_floorplan_id">ไฟแนนซ์ฟลอร์แพลน</label>
                            <select className="form-control" id="finance_floorplan_id">
                              <option value="0"> --- เลือกไฟแนนซ์ฟลอร์แพลน --- </option>
                              {car_floorplan_finance_list}
                            </select>
                          </div>

                          {/*TODO : เพิ่มวันเริ่มฟลอร์แพลนกับดอกเบี้ยต่อปี*/}
                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_start_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="floorplan_start_date">วันเริ่มเล่มฟลอร์แพลน</label>
                            <br/>
                            <DatePicker
                                type="text"
                                className="form-control col-sm-12"
                                name="floorplan_start_date"
                                placeholder=""
                                selected={this.state.floorplan_start_date}
                                value={this.state.floorplan_start_date}
                                onChange={this.handleFloorplanStartDate}
                                dateFormat="yyyy-MM-dd"
                            />
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_due_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="floorplan_due_date">วันครบกำหนดเล่มฟลอร์แพลน</label>
                            <br/>
                            <DatePicker
                              type="text"
                              className="form-control col-sm-12"
                              name="floorplan_due_date"
                              placeholder=""
                              selected={this.state.floorplan_due_date}
                              value={this.state.floorplan_due_date}
                              onChange={this.handleFloorplanDueDate}
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>

                          <div
                              className={
                                this.state.form_controls.length > 0 ?
                                    (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_annual_interest' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                    :  "hidden"
                              }
                          >
                            <label htmlFor="floorplan_annual_interest">% ดอกเบี้ยฟลอร์แพลนต่อปี</label>
                            <input type="text"
                                   className="form-control hidden "
                                   name="floorplan_annual_interest"
                                   placeholder=""
                                   ref="floorplan_annual_interest"
                                   value={this.state.floorplan_annual_interest}
                                   onChange={()=>{}}
                                   // onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                suffix={' %'}
                                decimalScale={2}
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({floorplan_annual_interest: value})
                                }}
                            />
                          </div>
                        </div>

                        <div className="row" style={styles.bottomLine}>
                          <div className="form-group col-sm-12">
                            <label htmlFor="web_info">รายละเอียดออฟชั่น อื่นๆ เช่น Airbag 1ใบ,กระจกไฟฟ้า,ล้อแม็กซ์,ไฟตัดหมอก,ABS,หลังคาแคร์รี่บอย,เบาะผ้า,วิทยุ CD MP3</label>
                            <ReactQuill theme="snow"
                                        value={this.state.car_feature_other}
                                        placeholder={'เช่น Airbag 1ใบ,กระจกไฟฟ้า,ล้อแม็กซ์,ไฟตัดหมอก,ABS,หลังคาแคร์รี่บอย,เบาะผ้า,วิทยุ CD MP3'}
                                        onChange={(val) => {
                                          this.setState({
                                            car_feature_other: val
                                          })
                                        }}
                            />
                          </div>
                        </div>

                        <div className="row" style={styles.bottomLine}>
                          <div className="form-group col-sm-12">
                            <label htmlFor="web_info">ข้อความค่างวดแสดงที่เว็บ เช่น ดาวน์ 7 บาท / งวด 9,XXX * 84</label>
                            <input
                              type="text" className="form-control"
                              name="web_info"
                              value={this.state.web_info}
                              placeholder="ข้อความค่างวดแสดงที่เว็บ เช่น ดาวน์ 7 บาท / งวด 9,XXX * 84"
                              onChange={(e)=> {
                                this.setState({
                                  web_info: e.target.value
                                })
                              }}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header card-header-info">
                    <strong>เพิ่มรูปรถ</strong>
                  </div>

                  <div className="card-block">
                    <div className="row">

                      {
                        PORT === 9047 ? (
                          <div className="form-group col-sm-2">
                            <label htmlFor="name">0) หน้าปกรถ <span className="text-danger">**</span></label>
                            <div style={styles.fileInputWrapper}>
                              <button style={styles.btnFileInput}>
                                <img src={'img/add_image.png'}
                                     style={styles.btnFileInputImage}
                                     ref="img_cover_car"
                                     alt="หน้าปกรถ"
                                />
                              </button>
                              <input
                                style={styles.fileInput}
                                type="file" name="cover_car"
                                onChange={this.handleUploadFile}
                              />
                            </div>
                          </div>
                        ) : null
                      }

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">1) หน้าตรง <span className="text-danger">**</span></label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_car"
                                 alt="หน้ารถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_car"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">2) หน้าซ้าย</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_right"
                                 alt="มุมขวา"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_right"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">3) หน้าขวา</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_left"
                                 alt="มุมซ้าย"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_left"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">4) หลังตรง </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_side_car_left"
                                 alt="ด้านซ้าย"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="side_car_left"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">5) หลังซ้าย</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_side_car_right"
                                 alt="ด้านขวา"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="side_car_right"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">6) หลังขวา</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_back_straight"
                                 alt="มุมตรง"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="back_straight"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">7) ซ้ายตรง</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_console_car"
                                 alt="คอนโซลรถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="console_car"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">8) ขวาตรง</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_condition_in_car"
                                 alt="สภาพภายในรถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="condition_in_car"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">9) เครื่องยนต์</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_engine_car"
                                 alt="เครื่องยนต์"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="engine_car"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">10) เบาะหน้าขวา</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_plate_car"
                                 alt="เพลทรถยนต์"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="plate_car"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">11) เบาะหลังขวา</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_other_car"
                                 alt="ภาพอื่นๆ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="other_car"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">12) คอนโซล</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_console"
                                 alt="ภาพอื่นๆ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="console"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">13) เกียร์</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_gear"
                                 alt="เกียร์"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="gear"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">14) เครื่องเสียง</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_audio_equipment"
                                 alt="เครื่องเสียง"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="audio_equipment"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">15) หน้าปัด</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_dial"
                                 alt="หน้าปัด"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="dial"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">16) {PORT===9008 ? 'น้ำมันเครื่อง' : 'ท่อแคท' }  </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_catalyticconverter"
                                 alt="ท่อแคท"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="catalyticconverter"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">17) ปียางรถ</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_yearoftire"
                                 alt="ปียางรถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="yearoftire"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">18) คัทซี</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_chassis"
                                 alt="คัทซี"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="chassis"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="plate">19) แผ่นเพลท</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_plate"
                                 alt="แผ่นเพลท"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="plate"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="engine_oil">20) คอน้ำมันเครื่อง</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_engine_oil"
                                 alt="คอน้ำมันเครื่อง"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="engine_oil"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>


                      <div className="form-group col-sm-2">
                        <label htmlFor="engine_oil">21) เบอร์ยางหน้าซ้าย </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_left_tire_number"
                                 alt="เบอร์ยางหน้าซ้าย"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_left_tire_number"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="engine_oil">22) เบอร์ยางหน้าขวา </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_right_tire_number"
                                 alt="เบอร์ยางหน้าขวา"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_right_tire_number"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="engine_oil">23) เบอร์ยางหลังซ้าย </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_back_left_tire_number"
                                 alt="เบอร์ยางหลังซ้าย"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="back_left_tire_number"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="engine_oil">24) เบอร์ยางหลังขวา </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_back_right_tire_number"
                                 alt="เบอร์ยางหลังขวา"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="back_right_tire_number"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="engine_oil">25) กุญแจรถ </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_car_key"
                                 alt="กุญแจรถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="car_key"
                            onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">26) อื่นๆ 1</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_other1"
                                 alt="อื่นๆ 1"
                            />
                          </button>
                          <input
                              style={styles.fileInput}
                              type="file" name="other1"
                              onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">27) อื่นๆ 2</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_other2"
                                 alt="อื่นๆ 2"
                            />
                          </button>
                          <input
                              style={styles.fileInput}
                              type="file" name="other2"
                              onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">28) อื่นๆ 3</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_other3"
                                 alt="อื่นๆ 3"
                            />
                          </button>
                          <input
                              style={styles.fileInput}
                              type="file" name="other3"
                              onChange={this.handleUploadFile}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="row"><span className="text-danger">**</span>&nbsp;เลือกอย่างน้อยภาพหน้ารถ &nbsp;&nbsp; สามารถเพิ่มเติมหรือปรับเปลี่ยนภายหลังได้</div>
                  </div>
                </div>
              </div>

              <div className={PORT === 9006 ? "col-sm-12" : "hidden" } >
                <div className="card">
                  <div className="card-header card-header-info">
                    <strong>เพิ่มรูปรถ(ก่อนแต่ง)</strong>
                  </div>

                  <div className="card-block">
                    <div className="row">
                      <div className="form-group col-sm-2">
                        <label htmlFor="name">
                          1) หน้ารถ หน้าตรง <span className="text-danger hidden">**</span>
                        </label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_car2"
                                 alt="หน้ารถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_car2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">2) หน้ารถ มุมขวา</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_right2"
                                 alt="มุมขวา"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_right2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">3) หน้ารถ มุมซ้าย</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_front_left2"
                                 alt="มุมซ้าย"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="front_left2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">4) ข้างรถ ด้านซ้าย</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_side_car_left2"
                                 alt="ด้านซ้าย"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="side_car_left2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">5) ข้างรถ ด้านขวา</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_side_car_right2"
                                 alt="ด้านขวา"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="side_car_right2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">6) หลังรถ มุมตรง</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_back_straight2"
                                 alt="มุมตรง"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="back_straight2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">7) คอนโซลรถ</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_console_car2"
                                 alt="คอนโซลรถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="console_car2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">8) สภาพภายในรถ</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_condition_in_car2"
                                 alt="สภาพภายในรถ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="condition_in_car2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">9) เครื่องยนต์</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_engine_car2"
                                 alt="เครื่องยนต์"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="engine_car2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">10) เพลทรถยนต์</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_plate_car2"
                                 alt="เพลทรถยนต์"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="plate_car2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                      <div className="form-group col-sm-2">
                        <label htmlFor="name">11) ภาพอื่นๆ</label>
                        <div style={styles.fileInputWrapper}>
                          <button style={styles.btnFileInput}>
                            <img src={'img/add_image.png'}
                                 style={styles.btnFileInputImage}
                                 ref="img_other_car2"
                                 alt="ภาพอื่นๆ"
                            />
                          </button>
                          <input
                            style={styles.fileInput}
                            type="file" name="other_car2"
                            onChange={this.handleUploadFile2}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="row hidden"><span className="text-danger">**</span>&nbsp;เลือกอย่างน้อยภาพหน้ารถ &nbsp;&nbsp; สามารถเพิ่มเติมหรือปรับเปลี่ยนภายหลังได้</div>
                  </div>
                </div>
              </div>

              <div
                   className={
                     checkGroupPermission(110, this.state.grouppermissions).created ? "col-sm-12" : "hidden"
                   }
               >
                <div className="card">
                  <div className="card-header card-header-info">
                    <strong>ตำหนิรถ</strong>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      {
                        this.state.car_blames.map(car_blame => this.renderCarBlames(car_blame))
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div
                   className={
                     checkGroupPermission(106, this.state.grouppermissions).created ? "col-sm-12" : "hidden"
                   }
              >
                <div className="card">
                  <div className="card-header card-header-warning">
                    <strong>ราคาขาย/ค่าใช้จ่าย (ซื้อเข้า) </strong>
                  </div>

                  <div className="card-block">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row" style={styles.bottomLine}>
                          <div className="form-group col-sm-12">
                            <label htmlFor="sale-price">ราคาขาย</label>
                            <input type="text"
                                   className="form-control hidden"
                                   name="car_sale_price"
                                   placeholder=""
                                   ref="car_sale_price"
                                   value={this.state.car_sale_price}
                                   onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ราคาขาย"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({car_sale_price: value}, () => {
                                    this.handleOnChange()
                                  })

                                }}
                            />
                          </div>
                        </div>

                        <div className="row"  style={styles.bottomLine} >
                          <div className="form-group col-sm-3">
                            <label htmlFor="cost">ราคาซื้อ (ไม่รวม VAT)</label>
                            <input type="text"
                                   className="form-control hidden "
                                   name="car_cost"
                                   placeholder=""
                                   ref="car_cost"
                                   value={this.state.car_cost}
                                   onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ราคาซื้อ"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({car_cost: value}, () => {
                                    this.handleOnChange()
                                  })

                                }}
                            />
                          </div>


                          <div className="form-group col-sm-3">
                            <label htmlFor="cost-tax">ภาษีซื้อ (VAT ซื้อ)&nbsp;</label>
                            <button type="button" onClick={() => {
                              let car_cost = this.state.car_cost === '' ? 0 : Number(this.state.car_cost);
                              let vat = car_cost * this.state.vatPercent;
                              let vat_fix = parseFloat(String(vat)).toFixed(2);

                              this.setState({
                                vat_buy : vat_fix
                              });

                            }} >คำนวณ</button>

                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ภาษีซื้อ(Vat ซื้อ)"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.vat_buy}
                                onValueChange = {(values) => {
                                  const {value} = values;
                                  this.setState({vat_buy: value},()=>{
                                     this.handleOnChange()
                                  })

                                }}
                            />
                          </div>


                          <div className="form-group col-sm-6 hidden">
                            <label htmlFor="cost-repair">ค่าซ่อมบำรุง</label>
                            <input
                                type="text"
                                className="form-control hidden"
                                name="car_cost_repair"
                                placeholder=""
                                ref="car_cost_repair"
                                value={this.state.car_cost_repair}
                                onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control hidden"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าซ่อมบำรุง"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  //if(value==='')
                                  //alert('ค่าซ่อมบำรุง=0')
                                  this.setState({car_cost_repair: value}, () => {
                                    this.handleOnChange()
                                  })

                                }}
                            />
                          </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="cost-tax">ค่าภาษีรถยนต์</label>
                            <input
                                type="text"
                                className="form-control hidden"
                                name="car_cost_tax"
                                placeholder=""
                                ref="car_cost_tax"
                                value={this.state.car_cost_tax}
                                onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าภาษีรถยนต์"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({car_cost_tax: value}, () => {
                                    this.handleOnChange()
                                  })

                                }}
                            />
                          </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="car_cost_act">ค่าใช้จ่าย พ.ร.บ.</label>
                            <input
                                type="text"
                                className="form-control hidden"
                                name="car_cost_act"
                                placeholder=""
                                ref="car_cost_act"
                                value={this.state.car_cost_act}
                                onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าใช้จ่าย พ.ร.บ."
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({car_cost_act: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>


                          <div className="form-group col-sm-6">
                            <label htmlFor="cost-commissions">ค่าคอมมิชชั่น/ค่าแนะนำซื้อรถเข้า</label>
                            <input
                                type="text"
                                className="form-control hidden"
                                name="car_cost_commissions"
                                placeholder=""
                                ref="car_cost_commissions"
                                value={this.state.car_cost_commissions}
                                onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าคอมมิชชั่น/ค่าแนะนำซื้อรถเข้า"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({car_cost_commissions: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_operation">ค่าดำเนินการ</label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าดำเนินการ"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_operation}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_operation: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_service">ค่าบริการ</label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าบริการ"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_service}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_service: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_transport">ค่าขนย้าย</label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าขนย้าย"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_transport}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_transport: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_porobor_lan">ค่า พรบ. จากลาน</label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่า พรบ. จากลาน"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_porobor_lan}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_porobor_lan: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_bangkhab_phasi_lan">ค่าบังคับต่อภาษี จากลาน</label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าบังคับต่อภาษี จากลาน"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_bangkhab_phasi_lan}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_bangkhab_phasi_lan: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_bangkhab_xon_lan">
                              {
                                PORT === 9043 ? 'ค่าบังคับโอนเข้า A.T. จากลาน': 'ค่าบังคับโอนเข้าเต็นท์ จากลาน'
                              }
                            </label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder={PORT === 9043 ? 'ค่าบังคับโอนเข้า A.T. จากลาน': 'ค่าบังคับโอนเข้าเต็นท์ จากลาน'}
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_bangkhab_xon_lan}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_bangkhab_xon_lan: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="cost_check_ton">ค่าเช็คต้น</label>
                            <NumberFormat
                                className="form-control"
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="ค่าเช็คต้น"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.cost_check_ton}
                                onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({cost_check_ton: value}, () => {
                                    this.handleOnChange()
                                  })
                                }}
                            />
                          </div>

                        </div>

                        <div className="row">


                          <div className="form-group col-sm-6 hidden">
                            <label htmlFor="cost-expenses-other">ค่าใช้จ่ายอื่นๆ</label>
                            <input
                              type="text"
                              className="form-control hidden"
                              name="car_cost_expenses_other"
                              placeholder=""
                              ref="car_cost_expenses_other"
                              value={this.state.car_cost_expenses_other}
                              onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                              className="form-control hidden"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ค่าใช้จ่ายอื่นๆ"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({car_cost_expenses_other: value}, () => {
                                  this.handleOnChange()
                                })
                              }}
                            />
                          </div>

                          <div className="form-group col-sm-6 hidden">
                            <label htmlFor="cost-recipe-other">รายรับอื่นๆจากการขาย</label>
                            <input
                              type="text"
                              className="form-control hidden"
                              name="car_cost_recipe_other"
                              placeholder=""
                              ref="car_cost_recipe_other"
                              value={this.state.car_cost_recipe_other}
                              onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                              className="form-control hidden"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="รายรับอื่นๆจากการขาย"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({car_cost_recipe_other: value}, () => {
                                  this.handleOnChange()
                                })
                              }}
                            />
                          </div>

                          <div className="form-group has-warning col-sm-12 mt-1">
                            <label htmlFor="net-profit">กำไรสุทธิ(ประมาณการ)</label>
                            <input type="text"
                                   className="form-control hidden"
                                   name="car_net_profit"
                                   placeholder="กำไรสุทธิ"
                                   ref="car_net_profit"
                                   value={this.state.car_net_profit}
                                   onChange={this.handleOnChangeNoSet}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="กำไรสุทธิ"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.car_net_profit}
                              displayType="text"
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <div className="card-block">
                  <button type="submit" className="btn btn-success" style={styles.buttonHeight}>
                    <i className="icon-check"/> &nbsp;บันทึกข้อมูลรถใหม่
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarNew)

const styles = {
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 100,
    width: 100,
  },
  btnFileInput: {
    width: 100,
    height: 100,
    display: 'inline-block',
    backgroundColor: '#D2DDE2',
    backgroundImage: 'linear-gradient(to bottom, #D2DDE2, #FCF8FF)',
    backgroundRepeat: 'repeat-x',

    color: '#ffffff',
    textShadow: '0 -1px 0 rgba(0, 0, 0, 0.25)'
  },
  btnFileInputImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 100,
    height: 100
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 100,
    width: 100,
    cursor: 'pointer'
  },
  toUpper: {
    textTransform: 'uppercase',
  },
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    marginTop: 16,
  },

  buttonHeight: {
    height: 40,
  }
}
