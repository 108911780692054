export const NumberUtil = {
    addCommas(nStr){

      if (typeof nStr!=='string'){
        if(typeof nStr === 'number'){
          nStr = String(nStr.toFixed(2))
        } else {
          nStr = String(nStr)
        }
      }

      nStr += '';
      let x = nStr.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      let rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        // eslint-disable-next-line
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return x1 + x2;
    },
    mobileFormat(mobileNumber){

      if (mobileNumber.length > 0) {
        if (mobileNumber.includes('-')) {
          return mobileNumber
        }
      }

      const splitAt = index => x => [x.slice(0, index), x.slice(index)]
      let splitMobile = splitAt(3)(mobileNumber)
      return splitMobile[0] + "-" + splitMobile[1]
    }
  }